import { put, cancel, select, call } from "redux-saga/effects";
import * as actions from "../actions/orders";
import * as api from "../../api/orders";

import moment from "moment";

const getOrdersTodayFetched = state => state.orders.ordersTodayFetched;
const getOrdersTomorrowFetched = state => state.orders.ordersTomorrowFetched;
const getOrdersToday = state => state.orders.ordersToday;
const getOrdersTomorrow = state => state.orders.ordersTomorrow;
const getOrdersArchive = state => state.orders.ordersArchive;
const getOrdersArchiveDates = state => state.orders.ordersArchiveDates;
const getUser = state => state.auth.user;
const getOrdersFilters = state => state.orders.filters;

export function* ordersTodayFetchSaga(action) {
  let isFetched = yield select(getOrdersTodayFetched);

  if (isFetched) {
    yield cancel();
  } else {
    let orders = yield call(api.getToday);
    yield put(actions.ordersTodaySave(orders));
  }
}

export function* ordersTomorrowFetchSaga(action) {
  let isFetched = yield select(getOrdersTomorrowFetched);

  if (isFetched) {
    yield cancel();
  } else {
    let orders = yield call(api.getTomorrow);
    yield put(actions.ordersTomorrowSave(orders));
  }
}

export function* ordersUpdateSaga(action) {
  let user = yield select(getUser);
  let filters = yield select(getOrdersFilters);

  let order = action.payload;
  let now = moment();
  let orderDate = moment(order.when);
  let archiveDates = yield select(getOrdersArchiveDates);

  let daysDifference = now
    .startOf("day")
    .diff(orderDate.startOf("day"), "days");
  let isToday = daysDifference === 0 ? true : false;
  let isTomorrow = daysDifference === -1 ? true : false;
  let isArchive = moment(orderDate).isBetween(
    moment(archiveDates.startDate).startOf("day"),
    moment(archiveDates.endDate).endOf("day"),
    null,
    "[]"
  );

  if (user.GroupId !== order.GroupId) {
    yield put(actions.ordersUpdateDelete(action.payload));
    yield cancel();
  }

  if (
    filters.CompanyId &&
    parseInt(filters.CompanyId) !== parseInt(order.CompanyId)
  ) {
    yield put(actions.ordersUpdateDelete(action.payload));
    yield cancel();
  }

  if (
    filters.OrdererId &&
    parseInt(filters.OrdererId) !== parseInt(order.OrdererId)
  ) {
    yield put(actions.ordersUpdateDelete(action.payload));
    yield cancel();
  }

  if (
    filters.DriverId &&
    parseInt(filters.DriverId) !== parseInt(order.DriverId)
  ) {
    yield put(actions.ordersUpdateDelete(action.payload));
    yield cancel();
  }

  if (
    filters.driverNumber &&
    parseInt(filters.driverNumber) !== parseInt(order.driverNumber)
  ) {
    yield put(actions.ordersUpdateDelete(action.payload));
    yield cancel();
  }

  if (filters.costCenter && filters.costCenter !== order.costCenter) {
    yield put(actions.ordersUpdateDelete(action.payload));
    yield cancel();
  }

  if (isToday) {
    let orders = yield select(getOrdersToday);
    let index = yield orders.findIndex(el => order.id === el.id);

    if (index > -1) {
      orders[index] = order;
    } else {
      orders.push(order);
    }

    yield orders.sort((a, b) => moment(a.when).diff(moment(b.when)));
    yield put(actions.ordersTodaySave(orders));
  }
  if (isTomorrow) {
    let orders = yield select(getOrdersTomorrow);
    let index = yield orders.findIndex(el => order.id === el.id);

    if (index > -1) {
      orders[index] = order;
    } else {
      orders.push(order);
    }

    yield orders.sort((a, b) => moment(a.when).diff(moment(b.when)));
    yield put(actions.ordersTomorrowSave(orders));
  }

  if (isArchive) {
    let orders = yield select(getOrdersArchive);
    let index = yield orders.findIndex(el => order.id === el.id);

    if (index > -1) {
      orders[index] = order;
    } else {
      orders.push(order);
    }

    yield orders.sort((a, b) => moment(a.when).diff(moment(b.when)));
    yield put(actions.ordersArchiveSave(orders));
  }
}

export function* ordersUpdateDeleteSaga(action) {
  let order = action.payload;
  order.id = parseInt(order.id);

  let ordersTomorrow = yield select(getOrdersTomorrow);
  let ordersToday = yield select(getOrdersToday);
  let ordersArchive = yield select(getOrdersArchive);

  let indexTomorrow = yield ordersTomorrow.findIndex(el => order.id === el.id);

  if (indexTomorrow > -1) {
    ordersTomorrow.splice(indexTomorrow, 1);
    yield put(actions.ordersTomorrowSave(ordersTomorrow));
  }

  let indexToday = yield ordersToday.findIndex(el => order.id === el.id);

  if (indexToday > -1) {
    ordersToday.splice(indexToday, 1);
    yield put(actions.ordersTodaySave(ordersToday));
  }

  let indexArchive = yield ordersArchive.findIndex(el => order.id === el.id);

  if (indexArchive > -1) {
    ordersArchive.splice(indexArchive, 1);
    yield put(actions.ordersArchiveSave(ordersArchive));
  }
}

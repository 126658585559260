import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import axios from "axios";
import * as actionCreators from "../../store/actions/index";

import DriversTimetable from "./DriversTimetable";

import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import { InlineDatePicker } from "material-ui-pickers/DatePicker";

class Drivers extends Component {
  state = {
    showTable: true,
    selectedDate:
      this.props.order && moment(this.props.order.when).isValid()
        ? moment(this.props.order.when).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD")
  };

  handleDateChange = date => {
    this.setState({ selectedDate: moment(date).format("YYYY-MM-DD") });
  };

  changeDay = number => {
    let date = this.state.selectedDate;
    date = moment(date)
      .add(number, "day")
      .format("YYYY-MM-DD");

    this.setState({ selectedDate: date });
  };

  removeDriver = () => {
    let order = { DriverId: null, driverConfirmation: false };

    axios
      .put("orders/" + this.props.order.id, order)
      .then(res => {
        //ugly
        this.props.driversModalOpen({ order: null, directPick: true });
        this.setState({ showTable: false }, () =>
          this.setState({ showTable: true })
        );
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Nie udało się usunąć";

        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.driversModalClose}
      >
        <AppBar position="fixed">
          <Toolbar variant="dense">
            <div className={classes.flexGrow}></div>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.props.driversModalClose}
              aria-label="Zamknij"
            >
              <Icon>close</Icon>
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.barMargin}>
          <header className={classes.flexCenter}>
            {this.props.order ? (
              <Button onClick={this.removeDriver} color="secondary">
                Usuń
              </Button>
            ) : null}

            <div style={{ flexGrow: 0.6 }}></div>
            <Icon
              onClick={() => this.changeDay(-1)}
              style={{ cursor: "pointer" }}
            >
              keyboard_arrow_left
            </Icon>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <InlineDatePicker
                className="bigDatePicker"
                value={this.state.selectedDate}
                onChange={this.handleDateChange}
                format="dddd, DD.MM"
              />
            </MuiPickersUtilsProvider>
            <Icon
              onClick={() => this.changeDay(1)}
              style={{ cursor: "pointer" }}
            >
              keyboard_arrow_right
            </Icon>
            <div style={{ flexGrow: 1 }}></div>
            <Button onClick={() => this.props.mapOpen("all")} color="primary">
              Pokaż mapę
            </Button>
          </header>
          {this.state.showTable && (
            <DriversTimetable
              date={this.state.selectedDate}
              selectedOrder={this.props.order}
            />
          )}
        </div>
      </Dialog>
    );
  }
}

const styles = theme => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  flexGrow: {
    flexGrow: "1"
  },
  barMargin: {
    margin: "80px auto 20px auto"
  }
});

const mapStateToProps = state => {
  return {
    drivers: [...state.drivers.drivers],
    open: state.drivers.modalOpen,
    order: state.drivers.order
  };
};

const mapDispatchToProps = dispatch => {
  return {
    driversModalClose: () => dispatch(actionCreators.driversModalClose()),
    driversModalOpen: payload =>
      dispatch(actionCreators.driversModalOpen(payload)),
    mapOpen: payload => dispatch(actionCreators.mapOpen(payload))
  };
};
export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSnackbar(Drivers))
);

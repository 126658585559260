import React, { Component } from "react";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import { InlineDateTimePicker } from "material-ui-pickers/DateTimePicker";

import moment from "moment";
import "moment/locale/pl";

import { withSnackbar } from "notistack";

moment.locale("pl");

class DateInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: this.props.selectedDate
        ? this.props.selectedDate
        : new Date(),
      error: false
    };
  }
  componentWillReceiveProps({ selectedDate }) {
    if (selectedDate !== this.state.selectedDate) {
      this.setState({ selectedDate });
    }
  }

  handleDateChange = date => {
    this.setState({ selectedDate: date });
    this.props.onChange(date.format());
  };

  render() {
    const { selectedDate, error } = this.state;
    const { inline } = this.props;

    let output = inline ? (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <InlineDateTimePicker
          ampm={false}
          error={error}
          value={selectedDate}
          onChange={this.handleDateChange}
          className={this.props.inputClass}
        />
      </MuiPickersUtilsProvider>
    ) : (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <InlineDateTimePicker
          ampm={false}
          error={error}
          value={selectedDate}
          onChange={this.handleDateChange}
          fullWidth
          className={this.props.inputClass}
        />
      </MuiPickersUtilsProvider>
    );
    return output;
  }
}

export default withSnackbar(DateInput);

import React, { Component } from "react";
import axios from "axios";
import _ from "lodash";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import * as actionCreators from "../../store/actions/index";
import classNames from "classnames";

import Icon from "@material-ui/core/Icon";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import copy from "clipboard-copy";

import moment from "moment";

class OrderRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  confirm = (order) => {
    if (this.props.disableEditing) return null;

    let newOrder = { ...order };

    newOrder.confirmed = !newOrder.confirmed;
    axios.put("orders/" + newOrder.id + "/confirm", {
      confirmed: newOrder.confirmed,
    });

    this.props.ordersUpdate(newOrder);
    this.onChange("update", newOrder);
  };

  toggleDriverConfirmation = () => {
    if (this.props.disableEditing) return null;

    axios.put("orders/" + this.props.data.id, {
      driverConfirmation: !this.props.data.driverConfirmation,
    });

    let newOrder = { ...this.props.data };

    newOrder.driverConfirmation = !newOrder.driverConfirmation;
    this.props.ordersUpdate(newOrder);
    this.onChange("update", newOrder);
  };

  removeDriver = () => {
    if (this.props.disableEditing) return null;

    axios.put("orders/" + this.props.data.id, {
      DriverId: null,
    });

    this.onChange("driverRemove", { id: this.props.data.id });
  };

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  simpleEdit = (data) => {
    if (this.props.disableEditing) return;
    this.props.simpleEditorOpen(data);
  };

  sendToOPST = () => {
    if (this.props.disableEditing) return null;

    let newOrder = { ...this.props.data };

    axios
      .put("orders/" + newOrder.id + "/opst")
      .then((response) => {
        newOrder.status = "opst";
        this.onChange("update", newOrder);
        this.handleMenuClose();
      })
      .catch((err) => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  sendToXFIRMA = () => {
    if (this.props.disableEditing) return null;

    let newOrder = { ...this.props.data };

    axios
      .put("orders/" + newOrder.id + "/xfirma")
      .then((response) => {
        newOrder.status = "xfirma";
        this.onChange("update", newOrder);
        this.handleMenuClose();
      })
      .catch((err) => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  cancel = () => {
    if (this.props.disableEditing) return null;

    let newOrder = { ...this.props.data };
    newOrder.status = "cancelled";

    axios
      .delete("orders/" + newOrder.id)
      .then((response) => {
        this.props.ordersUpdate(newOrder);
        this.onChange("update", newOrder);
        this.props.simpleDialogClose();
        this.handleMenuClose();
      })
      .catch((err) => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  delete = () => {
    if (this.props.disableEditing) return null;
    let newOrder = { ...this.props.data };

    this.props.simpleDialogClose();
    this.handleMenuClose();

    axios
      .delete("orders/delete/" + newOrder.id)
      .then((res) => {
        this.onChange("delete", newOrder);
      })
      .catch((err) => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  onChange = (action, order) => {
    let event = new CustomEvent("orderChanged", {
      detail: { action: action, order: order },
    });
    document.dispatchEvent(event);
  };

  render() {
    let { data, classes } = this.props;
    const time = moment(data.when).format("HH:mm");

    const variants = {
      confirmed: {
        icon: "check_circle",
        color: "#a2cf6e",
      },
      other: {
        icon: "cancel",
        color: "#ab003c",
      },
    };

    const variant = data.confirmed ? variants.confirmed : variants.other;

    let panelClasses = ``;

    if (
      data.from.city === "Łódź" &&
      (data.to.city === "Warszawa" || data.to.city === "Warsaw")
    ) {
      panelClasses = `${classes.lodzwarszawa}`;
    } else if (
      data.to.city === "Łódź" &&
      (data.from.city === "Warszawa" || data.from.city === "Warsaw")
    ) {
      panelClasses = `${classes.warszawalodz}`;
    }

    let throughCitiesEqual = true;
    if (data.through.length) {
      throughCitiesEqual = data.through.every((val, i, arr) => val === arr[0]);
    }

    if (
      data.from.city === "Łódź" &&
      data.to.city === "Łódź" &&
      data.through.length &&
      throughCitiesEqual &&
      data.through[0].city === "Łódź"
    ) {
      panelClasses = `${classes.lodzlodz}`;
    }

    if (
      data.from.city === "Łódź" &&
      data.to.city === "Łódź" &&
      !data.through.length
    ) {
      panelClasses = `${classes.lodzlodz}`;
    }

    if (data.status === "cancelled") {
      panelClasses += ` ${classes.cancelled}`;
    }

    if (data.status === "opst") {
      panelClasses += ` ${classes.opst}`;
    }

    if (this.state.anchorEl != null) {
      panelClasses += ` ${classes.active}`;
    }

    let driverName = data.driver ? data.driver.name : "";
    let driverNumber = data.driverNumber ? data.driverNumber : "";

    if (!driverName && !driverNumber) {
      driverNumber = "---";
    }

    if (driverName) {
      let driverSplit = driverName.split(" ");
      let driverNewName = driverSplit[0] + " " + driverSplit[1];
      let driverCars = driverSplit.slice(2, driverSplit.length).join(" ");

      driverName = (
        <React.Fragment>
          {driverNewName}
          <br />
          {driverCars}
        </React.Fragment>
      );
    }

    let amountPassenger = "";
    let amountCompany = "";

    if (data.paymentMaker === "client") {
      amountPassenger =
        data.paymentAmount && data.paymentAmount.length
          ? data.paymentAmount
          : "---";
    } else {
      amountCompany =
        data.paymentAmount && data.paymentAmount.length
          ? data.paymentAmount
          : "---";
    }

    let clientInfo = (
      <DialogContent>
        <p>
          <strong
            onClick={() => {
              copy(data.client.name);
              this.props.enqueueSnackbar(
                "Imię i nazwisko skopiowane do schowka",
                {
                  variant: "success",
                }
              );
            }}
            style={{ cursor: "pointer" }}
          >
            {data.client.name}
          </strong>
        </p>
        <p
          onClick={() => {
            copy(data.client.tel);
            this.props.enqueueSnackbar("Numer skopiowany do schowka", {
              variant: "success",
            });
          }}
          style={{ cursor: "pointer" }}
        >
          tel: {data.client.tel}
        </p>
      </DialogContent>
    );

    let ordererInfo = (
      <DialogContent>
        <p>
          <strong>
            {data.orderer.name} ({data.company.name})
          </strong>
        </p>
        <p
          onClick={() => {
            copy(data.orderer.phone);
            this.props.enqueueSnackbar("Numer skopiowany do schowka", {
              variant: "success",
            });
          }}
          style={{ cursor: "pointer" }}
        >
          tel: {data.orderer.phone}
        </p>
        <p>
          email:{" "}
          <a href={"mailto:" + data.orderer.email}>{data.orderer.email}</a>
        </p>
      </DialogContent>
    );

    let cancelConfirmation = (
      <React.Fragment>
        <DialogContent>Na pewno chcesz anulować zlecenie?</DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.props.simpleDialogClose}>
            Nie
          </Button>
          <Button variant="contained" color="secondary" onClick={this.cancel}>
            Tak
          </Button>
        </DialogActions>
      </React.Fragment>
    );

    let deleteConfirmation = (
      <React.Fragment>
        <DialogContent>
          Na pewno chcesz <strong>usunąć</strong> zlecenie?
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.props.simpleDialogClose}>
            Nie
          </Button>
          <Button variant="contained" color="secondary" onClick={this.delete}>
            Tak
          </Button>
        </DialogActions>
      </React.Fragment>
    );

    let xfirmaConfirmation = (
      <React.Fragment>
        <DialogContent>Na pewno chcesz wysłać do XFirmy?</DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.props.simpleDialogClose}>
            Nie
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              this.sendToXFIRMA();
              this.props.simpleDialogClose();
            }}
          >
            Tak
          </Button>
        </DialogActions>
      </React.Fragment>
    );

    let opstConfirmation = (
      <React.Fragment>
        <DialogContent>Na pewno chcesz wysłać do OPST?</DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.props.simpleDialogClose}>
            Nie
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              this.sendToOPST();
              this.props.simpleDialogClose();
            }}
          >
            Tak
          </Button>
        </DialogActions>
      </React.Fragment>
    );

    let extrasInfo = (
      <React.Fragment>
        <DialogContent>
          XFIRMA: {data.extras && data.extras.xfirma_id} <br />
          OPST: {data.extras && data.extras.opst_id}
          <br />
          XTaxi: {data.extras && data.extras.xtaxi_id}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.props.simpleDialogClose()}
          >
            Ok
          </Button>
        </DialogActions>
      </React.Fragment>
    );

    let toAddress = data.to && data.to.address ? data.to.address : "";
    let fromAddress = data.from && data.from.address ? data.from.address : "";

    let toCity = data.to && data.to.city ? data.to.city : "";
    let fromCity = data.from && data.from.city ? data.from.city : "";

    return (
      <TableRow key={data.identifier} className={panelClasses}>
        <TableCell
          align="center"
          onDoubleClick={() => this.simpleEdit({ order: data, field: "when" })}
        >
          {time}
        </TableCell>
        <TableCell
          align="center"
          onDoubleClick={() =>
            this.simpleEdit({ order: data, field: "timeNeeded" })
          }
        >
          {data.timeNeeded}
        </TableCell>
        <TableCell
          align="center"
          onDoubleClick={() => this.simpleEdit({ order: data, field: "trip" })}
        >
          <strong>{fromCity}</strong> {fromAddress} -&nbsp;
          {data.through.map((el) => {
            if (_.isEmpty(el)) return null;

            return (
              <span key={el.address}>
                <strong>{el.city}</strong>{" "}
                {el.address.substr(0, el.address.indexOf(","))} -&nbsp;
              </span>
            );
          })}
          <strong>{toCity}</strong> {toAddress}
        </TableCell>
        <TableCell
          align="center"
          className={classes.cursorPointer}
          onClick={() => this.props.simpleDialogOpen(clientInfo)}
        >
          {data.client.name}
        </TableCell>
        <TableCell
          align="center"
          onDoubleClick={() =>
            this.simpleEdit({ order: data, field: "clientCount" })
          }
        >
          {data.client.count}
        </TableCell>
        <TableCell
          align="center"
          className={classNames(classes.cursorPointer, {
            [classes.greenText]: data.driverConfirmation,
            [classes.darkRedText]: !data.driverConfirmation,
          })}
          onClick={() => {
            if (this.props.disableEditing) return null;
            this.props.driversModalOpen({ order: data, directPick: true });
          }}
        >
          {driverName}
        </TableCell>
        <TableCell
          align="center"
          className={classNames({
            [classes.greenText]: data.driverConfirmation,
            [classes.darkRedText]: !data.driverConfirmation,
          })}
          onDoubleClick={() =>
            this.simpleEdit({ order: data, field: "driverNumber" })
          }
        >
          {driverNumber}
        </TableCell>
        <TableCell
          align="center"
          onDoubleClick={() =>
            this.simpleEdit({ order: data, field: "costCenter" })
          }
        >
          {data.costCenter}
        </TableCell>
        <TableCell
          align="center"
          style={{ backgroundColor: "rgba(33,150,243, 0.1)" }}
          onDoubleClick={() =>
            this.simpleEdit({ order: data, field: "paymentAmount" })
          }
        >
          {amountCompany}
        </TableCell>
        <TableCell
          align="center"
          style={{ backgroundColor: "rgba(33,150,243, 0.1)" }}
          onDoubleClick={() =>
            this.simpleEdit({ order: data, field: "paymentAmount" })
          }
        >
          {amountPassenger}
        </TableCell>

        <TableCell
          align="center"
          className={classes.cursorPointer}
          onClick={() => this.props.simpleDialogOpen(ordererInfo)}
        >
          {data.orderer.name} ({data.company.name})
        </TableCell>
        <TableCell
          align="center"
          onDoubleClick={() =>
            this.simpleEdit({ order: data, field: "flightNumber" })
          }
        >
          {data.flightNumber}
        </TableCell>
        <TableCell
          align="center"
          onDoubleClick={() => this.simpleEdit({ order: data, field: "notes" })}
        >
          {data.notes}
        </TableCell>
        <TableCell
          align="center"
          onDoubleClick={() =>
            this.simpleEdit({ order: data, field: "internalNotes" })
          }
        >
          {data.internalNotes}
        </TableCell>
        <TableCell
          align="center"
          style={{
            padding: 0,
            position: "relative",
            backgroundColor: "white",
            paddingLeft: "5px",
          }}
        >
          {data.status === "opst" && <div className={classes.marker}>OPST</div>}
          {data.status === "xfirma" && (
            <div className={classes.marker}>XFIRMA</div>
          )}
          <div className={classes.flex}>
            {variant ? (
              <div style={{ color: variant.color }}>
                <Icon
                  className={classes.cursorPointer}
                  onClick={() => {
                    if (this.props.disableEditing) return null;
                    this.confirm(data);
                  }}
                >
                  {variant.icon}
                </Icon>
              </div>
            ) : null}
            {!this.props.disableEditing && (
              <React.Fragment>
                <IconButton
                  onClick={this.handleMenuOpen}
                  className="smallButton"
                >
                  <Icon>more_vert</Icon>
                </IconButton>
                <Menu
                  id="order-actions-menu"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleMenuClose}
                >
                  {(data.status === "opst" || data.status === "xfirma") &&
                  data.extras &&
                  (data.extras.opst_id ||
                    data.extras.xfirma_id ||
                    data.extras.xtaxi_id) ? (
                    <MenuItem
                      onClick={() => {
                        this.props.simpleDialogOpen(extrasInfo);
                        this.handleMenuClose();
                      }}
                    >
                      Identyfikator w OPST
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    onClick={() => {
                      this.props.ordersFormOpen(data);
                      this.handleMenuClose();
                    }}
                  >
                    Edytuj
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      this.props.simpleDialogOpen(opstConfirmation);
                      this.handleMenuClose();
                    }}
                  >
                    Wyślij do OPST
                  </MenuItem>

                  {this.props.user.role === "admin" &&
                  data.status !== "cancelled" ? (
                    <MenuItem
                      onClick={() => {
                        this.props.simpleDialogOpen(xfirmaConfirmation);
                        this.handleMenuClose();
                      }}
                    >
                      Wyślij do XFirmy
                    </MenuItem>
                  ) : null}
                  <Divider />
                  {data.driver ? (
                    <MenuItem
                      onClick={() => {
                        this.handleMenuClose();
                        this.props.mapOpen(data.driver.id);
                      }}
                    >
                      Pozycja kierowcy
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    onClick={() => {
                      this.toggleDriverConfirmation();
                      this.handleMenuClose();
                    }}
                  >
                    Przełącz potwierdzenie kierowcy
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.removeDriver();
                      this.handleMenuClose();
                    }}
                  >
                    Usuń kierowcę
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.props.ordersHistoryOpen(data);
                      this.handleMenuClose();
                    }}
                  >
                    Historia zmian
                  </MenuItem>
                  <Divider />
                  {data.status !== "cancelled" ? (
                    <MenuItem
                      onClick={() =>
                        this.props.simpleDialogOpen(cancelConfirmation)
                      }
                    >
                      Anuluj zlecenie
                    </MenuItem>
                  ) : null}

                  {this.props.user.role === "admin" &&
                  data.status === "cancelled" ? (
                    <MenuItem
                      onClick={() =>
                        this.props.simpleDialogOpen(deleteConfirmation)
                      }
                    >
                      Usuń zlecenie
                    </MenuItem>
                  ) : null}
                </Menu>
              </React.Fragment>
            )}
          </div>
        </TableCell>
      </TableRow>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: "3px",
    display: "flex",
    flexDirection: "column",
  },
  flexGrow: {
    flexGrow: "1",
  },
  cancelled: {
    textDecoration: "line-through",
  },
  lodzlodz: {
    backgroundColor: "#d2d2d2",
  },
  lodzwarszawa: {
    backgroundColor: "#FBFF61",
  },
  warszawalodz: {
    backgroundColor: "#ffafaf",
  },
  active: {
    backgroundColor: "lightskyblue !important",
  },
  warning: {
    color: "#f50057",
    fontSize: "12px",
    textAlign: "right",
    padding: "1em",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  greenText: {
    color: "#19651d",
    fontWeight: "bold",
  },
  darkRedText: {
    color: "#B22222",
    fontWeight: "bold",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  marker: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.7)",
    color: "#fff",
    padding: "6px",
    borderRadius: "3px",
  },
  opst: {
    opacity: "0.9",
  },
  label: {
    display: "block",
    fontSize: "12px",
  },
});

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    simpleDialogOpen: (payload) =>
      dispatch(actionCreators.simpleDialogOpen(payload)),
    simpleDialogClose: () => dispatch(actionCreators.simpleDialogClose()),
    mapOpen: (payload) => dispatch(actionCreators.mapOpen(payload)),
    ordersUpdate: (payload) => dispatch(actionCreators.ordersUpdate(payload)),
    ordersUpdateDelete: (payload) =>
      dispatch(actionCreators.ordersUpdateDelete(payload)),
    ordersHistoryOpen: (payload) =>
      dispatch(actionCreators.ordersHistoryOpen(payload)),
    ordersFormOpen: (order) => dispatch(actionCreators.ordersFormOpen(order)),
    simpleEditorOpen: (payload) =>
      dispatch(actionCreators.ordersSimpleEditorOpen(payload)),
    driversModalOpen: (payload) =>
      dispatch(actionCreators.driversModalOpen(payload)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withSnackbar(OrderRow))
);

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";

import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
import { bugsnagClient } from "../../bugsnag";

import logo from "./../../images/logo.svg";
import bgImg from "./../../images/loginBg.jpg";
import axios from "axios";

class Login extends Component {
  constructor(props) {
    super(props);
    document.title = `Fly`;
    this.state = {
      busy: false,
      login: "",
      password: "",
    };

    if (this.props.logout) {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      axios.defaults.headers.common["Authorization"] = "";
      this.props.authClear();
    }
  }

  handleChange = (e, a) => {
    let { name, value } = e.target;

    if (name === "login") {
      value = value.replace(/[^\w\s.]/gi, "").toLowerCase();
    }

    this.setState({
      [name]: value,
    });
  };

  _onSubmit = (e) => {
    e.preventDefault();
    this.setState({ busy: true });
    axios
      .post("users/login", {
        login: this.state.login,
        password: this.state.password,
      })
      .then((response) => {
        if (
          ["admin", "dispatcher", "dispatcherManager"].indexOf(
            response.data.user.role
          ) < 0
        ) {
          this.setState({ busy: false });
          this.props.enqueueSnackbar(
            "Nie masz uprawnień aby się zalogować w panelu",
            { variant: "error" }
          );
          return;
        }
        sessionStorage.setItem("user", JSON.stringify(response.data.user));
        sessionStorage.setItem("token", response.data.token);
        this.props.authSet(response.data.user);
        axios.defaults.headers.common["Authorization"] = response.data.token;
        this.setState({ busy: false });
        this.props.history.push("/");
      })
      .catch((err) => {
        let mess =
          err.response && err.response.data.error
            ? err.response.data.error
            : "Zły login lub hasło";

        this.props.enqueueSnackbar(mess, { variant: "error" });
        this.setState({ busy: false });

        bugsnagClient.notify(err, {
          metaData: {
            type: "LOGIN",
            err: err,
          },
        });
      });
  };

  render() {
    let classes = this.props.classes;
    return (
      <div className={classes.bg}>
        <CssBaseline />

        <div className={classes.layout}>
          <Paper className={classes.paper}>
            <img className={classes.avatar} src={logo} alt="fly" />

            <Typography variant="h5">Fly</Typography>
            <form className={classes.form} onSubmit={this._onSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="login">Login</InputLabel>
                <Input
                  name="login"
                  autoComplete="login"
                  autoFocus
                  onChange={this.handleChange}
                  value={this.state.login}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Hasło</InputLabel>
                <Input
                  name="password"
                  type="password"
                  autoComplete="password"
                  onChange={this.handleChange}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {this.state.busy ? (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                ) : (
                  "Zaloguj"
                )}
              </Button>
            </form>
          </Paper>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  bg: {
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "cover",
    width: "100vw",
    height: "100vh",
    position: "absolute",
  },
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    width: "48px",
    height: "48px",
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    authClear: () => dispatch(actionCreators.authClear()),
    authSet: (user) => dispatch(actionCreators.authSet(user)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withSnackbar(withStyles(styles)(Login)));

import { takeEvery, all } from 'redux-saga/effects';

import { companiesFetchSaga, companiesUpdateSaga } from './companies';
import { driversFetchSaga } from './drivers';
import { ordersTodayFetchSaga, ordersTomorrowFetchSaga, ordersUpdateSaga, ordersUpdateDeleteSaga } from './orders';
import * as actionTypes from '../actions/actionTypes';

export function* watchCompanies() {
    yield takeEvery(actionTypes.COMPANIES_FETCH, companiesFetchSaga);
    yield takeEvery(actionTypes.COMPANIES_UPDATE, companiesUpdateSaga);
}

export function* watchDrivers() {
    yield takeEvery(actionTypes.DRIVERS_FETCH, driversFetchSaga);
}

export function* watchOrders() {
    yield takeEvery(actionTypes.ORDERS_TODAY_FETCH, ordersTodayFetchSaga);
    yield takeEvery(actionTypes.ORDERS_TOMORROW_FETCH, ordersTomorrowFetchSaga);
    yield takeEvery(actionTypes.ORDERS_UPDATE, ordersUpdateSaga);
    yield takeEvery(actionTypes.ORDERS_UPDATE_DELETE, ordersUpdateDeleteSaga);
}

export function* rootSaga () {
    yield all([
        watchCompanies(),
        watchDrivers(),
        watchOrders(),
    ]);
}
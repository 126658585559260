import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import _ from "lodash";

import * as actionCreators from "../../store/actions/index";

import { withSnackbar } from "notistack";

import "./style.css";

import Pause from "./Pause";
import Trip from "./Trip";

class DriversTimetable extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      holidays: [],
      orders: [],
      income: [],
      date: this.props.date
        ? this.props.date
        : moment(new Date()).format("YYYY-MM-DD")
    };
  }

  componentDidMount() {
    this.props.driversFetch();
    this.loadData();
  }

  componentWillReceiveProps({ date }) {
    if (date !== this.state.date) {
      this.setState({ date }, () => {
        this.loadData();
      });
    }
  }

  loadData = async () => {
    let holidays = await this.loadHolidays();
    let orders = await this.loadOrders();
    let income = await this.loadIncome();
    this.setState({ orders, holidays, income });
  };

  loadHolidays = () => {
    return axios
      .get(`drivers/holidays`, { params: { date: this.state.date } })
      .then(res => {
        const holidays = res.data.holidays;
        return holidays;
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd!";

        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  loadIncome = () => {
    return axios
      .get(`drivers/income/` + moment(this.state.date).format("YYYY-MM-DD"))
      .then(res => {
        const income = res.data.income;
        return income;
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd!";

        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  loadOrders = () => {
    let start = moment(this.state.date)
      .add(-1, "day")
      .format("YYYY-MM-DD");
    let end = moment(this.state.date)
      .add(1, "day")
      .format("YYYY-MM-DD");

    return axios
      .get(`orders`, {
        params: {
          startDate: start,
          endDate: end
        }
      })
      .then(res => {
        const orders = res.data.orders;
        return orders;
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd!";

        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  createTimeLabels = () => {
    let table = [];
    for (let i = 0; i < 25; i++) {
      table.push(
        <li key={"timeLabel" + i}>
          <span className="time-label">{i}</span>
        </li>
      );
    }
    return table;
  };

  render() {
    let { orders, holidays } = this.state;
    let orderToSet = null;

    if (
      this.props.selectedOrder &&
      Math.abs(
        moment(this.state.date)
          .startOf("day")
          .diff(moment(this.props.selectedOrder.when).startOf("day"), "days")
      ) <= 1
    ) {
      orderToSet = this.props.selectedOrder;
    }

    let drivers = this.props.drivers.length ? this.props.drivers : [];
    let sortedDrivers = _.orderBy(drivers, ["order"], ["asc"]);

    return (
      <div className="timetable">
        <aside>
          <ul style={{ marginTop: "10px" }}>
            {sortedDrivers.map(driver => {
              let driverSplit = driver.name.split(" ");
              let driverName = driverSplit[0] + " " + driverSplit[1];
              let driverCars = driverSplit
                .slice(2, driverSplit.length)
                .join(" ");
              return (
                <li key={"driverLabel_" + driver.id}>
                  <span className="row-heading">{driverName}</span>
                  <span className="row-subheading">{driverCars}</span>
                </li>
              );
            })}
          </ul>
        </aside>
        <section>
          <time>
            <header>
              <ul>{this.createTimeLabels()}</ul>
            </header>
            <ul className="room-timeline">
              {sortedDrivers.map(driver => (
                <li key={"driverRow_" + driver.id}>
                  {holidays
                    .filter(h => h.UserId === driver.id)
                    .map(holiday => (
                      <Pause
                        key={"holiday_" + holiday.id}
                        item={holiday}
                        currentDate={this.state.date}
                      />
                    ))}

                  {orders.filter(h => h.DriverId === driver.id).map(order => (
                    <Trip
                      key={"trip_" + order.id}
                      item={order}
                      currentDate={this.state.date}
                    />
                  ))}
                  {orderToSet && (
                    <Trip
                      item={orderToSet}
                      currentDate={this.state.date}
                      driverId={driver.id}
                      selectable={true}
                      onRefresh={this.loadData}
                    />
                  )}
                </li>
              ))}
            </ul>
          </time>
        </section>
        <div className="amounts">
          <ul>
            {sortedDrivers.map(driver => (
              <li key={"driverIncome" + driver.id}>
                &nbsp;
                {this.state.income
                  .filter(i => i.DriverId === driver.id)
                  .map(income => income.amount)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    drivers: [...state.drivers.drivers]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    driversFetch: () => dispatch(actionCreators.driversFetch())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DriversTimetable));

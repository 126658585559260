import React, { Component } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";

import { bugsnagClient } from "../../bugsnag";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { withSnackbar } from "notistack";

import OrdersTable from "./OrdersTable";
import AddressInput from "../AddressInput";
import DateInput from "../DateInput";

class SimpleEditor extends Component {
  constructor(props) {
    super(props);

    let showAllFields = false;
    if (
      ["when", "trip", "clientCount", "flightNumber", "notes"].indexOf(
        this.props.selectedField
      ) > -1
    ) {
      showAllFields = true;
    }

    this.state = {
      showAllFields: showAllFields,
      busy: false,
      anchorEl: null,
      newOrder: {
        when: "",
        extraWhen: [],
        from: { address: "", city: "" },
        through: [],
        to: { address: "", city: "" },
        client: {
          count: 1,
          name: "",
          tel: ""
        },
        paymentMaker: "client",
        costCenter: "",
        notes: "",
        internalNotes: "",
        flightNumber: "",
        CompanyId: "",
        OrdererId: "",
        DriverId: "",
        driverNumber: "",
        timeNeeded: "",
        paymentAmount: "",
        confirmed: false,
        company: {},
        orderer: {}
      },
      pricings: [],
      user: JSON.parse(sessionStorage.getItem("user"))
    };
  }

  componentDidMount() {
    this.props.driversFetch();
  }

  componentWillReceiveProps({ selectedOrder, selectedDriver }) {
    if (selectedOrder && selectedOrder.id !== this.state.newOrder.id) {
      let order = {
        id: selectedOrder.id,
        when: selectedOrder.when,
        extraWhen: selectedOrder.extraWhen ? selectedOrder.extraWhen : [],
        from: selectedOrder.from,
        through: selectedOrder.through,
        to: selectedOrder.to,
        client: selectedOrder.client,
        paymentMaker: selectedOrder.paymentMaker,
        costCenter: selectedOrder.costCenter ? selectedOrder.costCenter : "",
        notes: selectedOrder.notes ? selectedOrder.notes : "",
        internalNotes: selectedOrder.internalNotes
          ? selectedOrder.internalNotes
          : "",
        flightNumber: selectedOrder.flightNumber,
        CompanyId: selectedOrder.CompanyId,
        OrdererId: selectedOrder.OrdererId,
        DriverId: selectedOrder.DriverId,
        driverNumber: selectedOrder.driverNumber
          ? selectedOrder.driverNumber
          : "",
        timeNeeded: selectedOrder.timeNeeded ? selectedOrder.timeNeeded : null,
        paymentAmount: selectedOrder.paymentAmount
          ? selectedOrder.paymentAmount
          : null,
        confirmed: selectedOrder.confirmed ? true : false,
        company: selectedOrder.company,
        orderer: selectedOrder.orderer
      };

      this.setState({ newOrder: order }, this.loadPricings);
    } else if (selectedDriver) {
      let newOrder = this.state.newOrder;
      newOrder.DriverId = selectedDriver;

      this.setState({ newOrder });
    }
  }

  loadPricings = () => {
    let companyId = this.state.newOrder.CompanyId;

    axios
      .get(`pricings/` + companyId)
      .then(res => {
        const pricings = res.data.pricings;
        this.setState({ pricings });
      })
      .catch(err => {
        this.props.enqueueSnackbar("Błąd! Nie udało się załadować cennika", {
          variant: "error"
        });
      });
  };

  handleInputChange = (e, a) => {
    let { name, value } = e.target;
    let newOrder = this.state.newOrder;

    // reset costCenter if paymentMaker changed to client
    if (name === "paymentMaker" && value === "client") {
      newOrder.costCenter = "";
    }

    newOrder[name] = value;
    this.setState({ newOrder });
  };

  handleClientChange = (e, a) => {
    let { name, value } = e.target;
    let newOrder = this.state.newOrder;

    if (name === "name") {
      value = value
        .toLowerCase()
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    }

    newOrder.client[name] = value;
    this.setState({ newOrder });
  };

  confirm = () => {
    let newOrder = { ...this.state.newOrder };

    newOrder.confirmed = !newOrder.confirmed;

    this.setState({ newOrder });
  };

  openPricing = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePricingClose = () => {
    this.setState({ anchorEl: null });
  };

  handlePricingSelect = price => {
    let newOrder = this.state.newOrder;

    newOrder.paymentAmount = price;
    this.setState({ newOrder });
  };

  handleFromChange = address => {
    let newOrder = this.state.newOrder;

    newOrder.from = address;
    this.setState({ newOrder });
  };

  handleToChange = address => {
    let newOrder = this.state.newOrder;

    newOrder.to = address;
    this.setState({ newOrder });
  };

  addThroughPoint = () => {
    let newOrder = this.state.newOrder;

    newOrder.through = [
      ...newOrder.through,
      { address: "", city: "", lat: "", lng: "" }
    ];
    this.setState({ newOrder });
  };

  handleThroughChange = (address, index) => {
    let newOrder = this.state.newOrder;

    newOrder.through[index] = address;
    this.setState({ newOrder });
  };

  removeThroughPoint = index => {
    let newOrder = this.state.newOrder;

    newOrder.through.splice(index, 1);
    this.setState({ newOrder });
  };

  handleDateChange = (date, fieldName, index = false) => {
    let newOrder = this.state.newOrder;
    if (index === false) {
      newOrder[fieldName] = date;
    } else {
      newOrder[fieldName][index] = date;
    }

    this.setState({ newOrder });
  };

  close = () => {
    this.props.ordersSimpleEditorClose();
  };

  save = () => {
    let order = { ...this.state.newOrder };

    if (!this.validate(order)) {
      this.props.enqueueSnackbar("Błędnie wypełniony formularz", {
        variant: "error"
      });
      return null;
    }
    this.setState({ busy: true });

    axios
      .put("orders/" + order.id, order)
      .then(response => {
        this.onChange("update", order);
        this.close();
      })
      .catch(err => {
        this.setState({ busy: false });
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Nie udało się zapisać";

        bugsnagClient.notify(err, {
          metaData: {
            type: "update/PUT",
            order: order,
            err: err
          }
        });

        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  onChange = (action, order) => {
    let event = new CustomEvent("orderChanged", {
      detail: { action: action, order: order }
    });
    document.dispatchEvent(event);
  };

  validate = newOrder => {
    if (
      !newOrder.when ||
      !newOrder.from.city ||
      !newOrder.CompanyId ||
      !newOrder.OrdererId
    )
      return false;
    return true;
  };

  normalizeDiactrics = string =>
    string
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\u0142/g, "l");

  render() {
    const { classes } = this.props;
    const { busy, newOrder, anchorEl } = this.state;
    const validated = this.validate(newOrder);
    let driverName = null;
    let pricingSelect = null;

    const variants = {
      confirmed: {
        icon: "check_circle",
        color: "#a2cf6e"
      },
      other: {
        icon: "cancel",
        color: "#ab003c"
      }
    };

    const variant = newOrder.confirmed ? variants.confirmed : variants.other;

    if (newOrder.DriverId) {
      let driver = this.props.drivers.find(d => d.id === newOrder.DriverId);
      driverName = driver.name;
    }

    // if (
    //   this.state.pricings.length &&
    //   newOrder.from.city &&
    //   newOrder.to.city &&
    //   !newOrder.through.length
    // ) {
    //   let pricings = this.state.pricings;

    //   filteredPricings = _.filter(pricings, p => {
    //     let arr = [
    //       this.normalizeDiactrics(p.city1),
    //       this.normalizeDiactrics(p.city2)
    //     ];
    //     let regex =
    //       "^(?=.*\\b" +
    //       this.normalizeDiactrics(newOrder.from.city) +
    //       "\\b)(?=.*\\b" +
    //       this.normalizeDiactrics(newOrder.to.city) +
    //       "\\b).*$";
    //     let srex = new RegExp(regex, "gm");

    //     return srex.exec(arr.join(" ")) !== null;
    //   });
    // }

    if (this.state.pricings.length) {
      pricingSelect = (
        <React.Fragment>
          <Button
            aria-haspopup="true"
            onClick={this.openPricing}
            color="primary"
            variant="contained"
            style={{ marginTop: "5px" }}
          >
            Cennik
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handlePricingClose}
          >
            {this.state.pricings.map(c => (
              <MenuItem
                key={"pricinglist" + c.id}
                onClick={() => {
                  this.handlePricingSelect(c.paymentAmount);
                  this.handlePricingClose();
                }}
              >
                <span style={{ fontSize: ".8rem" }}>{c.city1}</span>
                <Icon
                  style={{
                    verticalAlign: "middle",
                    margin: "0 1em",
                    fontSize: ".8rem"
                  }}
                >
                  compare_arrows
                </Icon>
                <span style={{ fontSize: ".8rem" }}>{c.city2}</span>
                <span style={{ fontSize: ".8rem", marginLeft: "1em" }}>
                  {c.type}
                </span>
                <span style={{ fontSize: ".8rem", marginLeft: "1em" }}>
                  {c.paymentAmount} PLN
                </span>
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      );
    }

    let simpleView = (
      <React.Fragment>
        <Grid item md={6}>
          <Grid container spacing={16}>
            <Grid item md={4}>
              <TextField
                autoFocus={this.props.selectedField === "timeNeeded"}
                label="Czas przejazdu"
                name="timeNeeded"
                type="number"
                step="1"
                onChange={this.handleInputChange}
                fullWidth
                value={newOrder.timeNeeded !== null ? newOrder.timeNeeded : ""}
                InputProps={{
                  type: "number",
                  step: "1",
                  endAdornment: (
                    <InputAdornment position="end">min</InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item md={8} />
            <Grid item md={4}>
              {newOrder.paymentMaker === "company" ? (
                <TextValidator
                  autoFocus={this.props.selectedField === "costCenter"}
                  label="CC/MPK/Karta"
                  name="costCenter"
                  type="text"
                  onChange={this.handleInputChange}
                  fullWidth
                  value={newOrder.costCenter}
                  disabled={newOrder.paymentMaker === "client"}
                  className={newOrder.costCenter ? "" : "inputEmpty"}
                />
              ) : (
                <Typography variant="overline" style={{ lineHeight: "3.66" }}>
                  Płatność u kierowcy
                </Typography>
              )}
            </Grid>

            <Grid item md={4}>
              <TextField
                autoFocus={this.props.selectedField === "paymentAmount"}
                label="Kwota"
                name="paymentAmount"
                type="number"
                step="0.01"
                onChange={this.handleInputChange}
                fullWidth
                value={
                  newOrder.paymentAmount !== null ? newOrder.paymentAmount : ""
                }
                InputProps={{
                  type: "number",
                  step: "0.01"
                }}
              />
            </Grid>

            <Grid item md={4} style={{ padding: "0.5em 1em" }}>
              {pricingSelect}
            </Grid>

            <Grid item md={4}>
              {newOrder.when && newOrder.from.city && newOrder.timeNeeded ? (
                <Button
                  color="primary"
                  size="small"
                  onClick={() =>
                    this.props.driversModalOpen({
                      order: newOrder,
                      directPick: false
                    })
                  }
                >
                  {driverName ? driverName : "Wybierz kierowcę"}
                </Button>
              ) : null}
            </Grid>
            <Grid item md={4}>
              <TextValidator
                autoFocus={this.props.selectedField === "driverNumber"}
                margin="dense"
                label="Nr. taxi"
                name="driverNumber"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newOrder.driverNumber}
              />
            </Grid>
            <Grid item md={4} style={{ textAlign: "center" }}>
              {variant ? (
                <div style={{ color: variant.color }}>
                  <Icon
                    className={classes.cursorPointer}
                    onClick={() => this.confirm(newOrder)}
                  >
                    {variant.icon}
                  </Icon>
                </div>
              ) : null}
              {newOrder.confirmed ? "Potwierdzone" : "Niepotwierdzone"}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <TextField
            autoFocus={this.props.selectedField === "internalNotes"}
            label="Notatki (widoczne dla kierowcy i dyspozytorów)"
            multiline
            rows="4"
            name="internalNotes"
            onChange={this.handleInputChange}
            value={newOrder.internalNotes}
            margin="normal"
            variant="outlined"
            fullWidth
          />
        </Grid>
      </React.Fragment>
    );

    let fullView = (
      <React.Fragment>
        <Grid item md={6}>
          <div className={classes.dateContainer} style={{ margin: 0 }}>
            <Typography variant="subtitle1" gutterBottom>
              Kiedy
            </Typography>
            <DateInput
              onChange={date => this.handleDateChange(date, "when")}
              selectedDate={newOrder.when}
              inputClass={newOrder.when ? "" : "inputEmpty"}
            />
            {newOrder.extraWhen.map((date, index) => (
              <div className={classes.flexRow} key={index}>
                <DateInput
                  onChange={date =>
                    this.handleDateChange(date, "extraWhen", index)
                  }
                  selectedDate={date}
                />
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => this.removeExtraDate(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            {newOrder.id ? null : (
              <Tooltip title="Zlecenie cykliczne" placement="right">
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  className={classes.throughButton}
                  onClick={this.addExtraDate}
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            )}
          </div>
          <div className={classes.tripContainer}>
            <Typography variant="subtitle1" gutterBottom>
              Trasa
            </Typography>
            <AddressInput
              onSelect={this.handleFromChange}
              label="Skąd"
              selectedAddress={newOrder.from.address}
              selectedAddressObj={newOrder.from}
              inputClass={newOrder.from.address ? "" : "inputEmpty"}
            />
            {newOrder.through.map((address, index) => (
              <div className={classes.flexRow} key={index}>
                <AddressInput
                  onSelect={address => this.handleThroughChange(address, index)}
                  label="Przez"
                  selectedAddress={address.address}
                  selectedAddressObj={address}
                />
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => this.removeThroughPoint(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <Tooltip title="Dodaj adres pośredni" placement="right">
              <Button
                size="small"
                variant="outlined"
                color="primary"
                className={classes.throughButton}
                onClick={this.addThroughPoint}
              >
                <AddIcon />
              </Button>
            </Tooltip>

            <AddressInput
              onSelect={this.handleToChange}
              label="Dokąd"
              selectedAddress={newOrder.to.address}
              selectedAddressObj={newOrder.to}
            />
          </div>
          <Grid container spacing={32} className={classes.othersContainer}>
            <Grid item md={6}>
              <TextValidator
                autoFocus={this.props.selectedField === "clientCount"}
                margin="dense"
                label="Ilość osób"
                name="count"
                type="number"
                inputProps={{ min: "1", max: "8", step: "1" }}
                onChange={this.handleClientChange}
                fullWidth
                value={newOrder.client.count}
                validators={["required", "minNumber:1", "maxNumber:8"]}
                errorMessages={[
                  "To pole jest wymagane",
                  "Podaj liczbę pasażerów",
                  "Jedna taksówka może zabrać maksymalnie ośmiu pasażerów"
                ]}
              />
              <TextValidator
                autoFocus={this.props.selectedField === "clientName"}
                margin="dense"
                label="Dane pasażera"
                name="name"
                type="text"
                onChange={this.handleClientChange}
                fullWidth
                value={newOrder.client.name}
              />
              <TextValidator
                autoFocus={this.props.selectedField === "clientTel"}
                margin="dense"
                label="Tel. Kontaktowy"
                name="tel"
                type="text"
                onChange={this.handleClientChange}
                fullWidth
                value={newOrder.client.tel}
              />
              <Grid container spacing={8}>
                <Grid item sm={6}>
                  <TextValidator
                    autoFocus={this.props.selectedField === "flightNumber"}
                    margin="dense"
                    label="Numer Lotu"
                    name="flightNumber"
                    type="text"
                    onChange={this.handleInputChange}
                    fullWidth
                    value={newOrder.flightNumber}
                  />
                </Grid>
                <Grid item sm={6} style={{ display: "flex" }}>
                  {newOrder.flightNumber && newOrder.flightNumber.length > 3 ? (
                    <Button
                      variant="outlined"
                      color="secondary"
                      style={{ alignSelf: "flex-end" }}
                      target="_blank"
                      size="small"
                      href={
                        "https://www.google.pl/search?q=" +
                        newOrder.flightNumber
                      }
                    >
                      Sprawdź
                      <Icon className={classes.rightIcon}>flight</Icon>
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6}>
              <FormControl component="fieldset" margin="dense">
                <FormLabel component="legend">Kto płaci</FormLabel>
                <RadioGroup
                  aria-label="payment maker"
                  name="paymentMaker"
                  value={newOrder.paymentMaker}
                  onChange={this.handleInputChange}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="company"
                    control={<Radio />}
                    label="Firma"
                  />
                  <FormControlLabel
                    value="client"
                    control={<Radio />}
                    label="Pasażer"
                  />
                </RadioGroup>
              </FormControl>
              {newOrder.paymentMaker === "client" ? (
                <Typography variant="overline" gutterBottom>
                  Płatność u kierowcy
                </Typography>
              ) : (
                <TextValidator
                  autoFocus={this.props.selectedField === "costCenter"}
                  margin="dense"
                  label="CC/MPK/Karta"
                  name="costCenter"
                  type="text"
                  onChange={this.handleInputChange}
                  fullWidth
                  value={newOrder.costCenter}
                  disabled={newOrder.paymentMaker === "client"}
                  className={newOrder.costCenter ? "" : "inputEmpty"}
                />
              )}
            </Grid>
          </Grid>
          <TextField
            autoFocus={this.props.selectedField === "notes"}
            label="Uwagi (Widoczne dla zamawiającego, kierowcy i dyspozytorów)"
            multiline
            rows="4"
            name="notes"
            onChange={this.handleInputChange}
            value={newOrder.notes}
            margin="normal"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item md={6}>
          <Grid container justify="center" spacing={16}>
            <Grid item md={6} style={{ margin: "2em 0" }}>
              <TextField
                autoFocus={this.props.selectedField === "paymentAmount"}
                label="Kwota"
                name="paymentAmount"
                type="number"
                step="0.01"
                onChange={this.handleInputChange}
                fullWidth
                value={
                  newOrder.paymentAmount !== null ? newOrder.paymentAmount : ""
                }
                InputProps={{
                  type: "number",
                  step: "0.01"
                }}
              />
            </Grid>
            <Grid item md={6} style={{ margin: "2em 0", padding: "1em" }}>
              {pricingSelect}
            </Grid>

            <Grid item md={6} style={{ margin: "1em 0" }}>
              <TextField
                autoFocus={this.props.selectedField === "timeNeeded"}
                label="Czas przejazdu"
                name="timeNeeded"
                type="number"
                step="1"
                onChange={this.handleInputChange}
                fullWidth
                value={newOrder.timeNeeded !== null ? newOrder.timeNeeded : ""}
                InputProps={{
                  type: "number",
                  step: "1",
                  endAdornment: (
                    <InputAdornment position="end">min</InputAdornment>
                  )
                }}
                className={newOrder.timeNeeded ? "" : "inputEmpty"}
              />
            </Grid>
            <Grid item md={6} style={{ margin: "1em 0", textAlign: "center" }}>
              {variant ? (
                <div style={{ color: variant.color }}>
                  <Icon
                    className={classes.cursorPointer}
                    onClick={() => this.confirm(newOrder)}
                  >
                    {variant.icon}
                  </Icon>
                </div>
              ) : null}
              {newOrder.confirmed ? "Potwierdzone" : "Niepotwierdzone"}
            </Grid>
            <Grid item md={6} style={{ display: "flex" }}>
              {newOrder.when && newOrder.from.city && newOrder.timeNeeded ? (
                <Button
                  color="primary"
                  onClick={() =>
                    this.props.driversModalOpen({
                      order: newOrder,
                      directPick: false
                    })
                  }
                >
                  {driverName ? driverName : "Wybierz kierowcę"}
                </Button>
              ) : null}
            </Grid>
            <Grid item md={6}>
              <TextValidator
                autoFocus={this.props.selectedField === "driverNumber"}
                margin="dense"
                label="Nr. taxi"
                name="driverNumber"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newOrder.driverNumber}
              />
            </Grid>
            <TextField
              autoFocus={this.props.selectedField === "internalNotes"}
              label="Notatki (widoczne dla kierowcy i dyspozytorów)"
              multiline
              rows="4"
              name="internalNotes"
              onChange={this.handleInputChange}
              value={newOrder.internalNotes}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );

    return (
      <Dialog
        open={true}
        aria-labelledby="form-dialog-simple-edit"
        fullWidth
        maxWidth={false}
        onClose={this.props.ordersSimpleEditorClose}
      >
        <DialogContent>
          <OrdersTable
            orders={[this.props.selectedOrder]}
            disableEditing={true}
          />
          <ValidatorForm ref="form" onSubmit={this.save}>
            <Grid container spacing={16}>
              {!this.state.showAllFields && (
                <Grid item md={12}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => this.setState({ showAllFields: true })}
                    style={{ float: "right" }}
                  >
                    Pokaż wszystkie pola
                  </Button>
                </Grid>
              )}

              {this.state.showAllFields ? fullView : simpleView}
              <Button
                color="primary"
                variant="contained"
                type="submit"
                style={{ display: "none" }}
              >
                submit
              </Button>
            </Grid>
          </ValidatorForm>
        </DialogContent>
        <DialogActions style={{ marginBottom: "1rem", marginRight: "1em" }}>
          <Button onClick={this.close} color="primary">
            Anuluj
          </Button>
          <div className={classes.relative}>
            <Button
              type="submit"
              onClick={this.save}
              variant="contained"
              color="secondary"
              disabled={busy || !validated}
            >
              Zapisz
            </Button>
            {busy && (
              <CircularProgress
                size={24}
                color="secondary"
                className={classes.buttonProgress}
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = theme => ({
  relative: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  cursorPointer: {
    cursor: "pointer"
  },
  throughButton: {
    margin: theme.spacing.unit,
    marginLeft: 0,
    padding: 0
  },
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  tripContainer: {
    margin: "2em 0",
    padding: "2em 1em",
    paddingTop: 0,
    borderLeft: "4px solid #8bc34a"
  },
  dateContainer: {
    margin: "2em 0",
    padding: "2em 1em",
    paddingTop: 0,
    borderLeft: "4px solid #ab003c"
  },
  othersContainer: {
    margin: "2em 0",
    padding: "2em 1em",
    paddingTop: 0,
    borderLeft: "4px solid #2196f3"
  },
  addressCard: {
    cursor: "move",
    backgroundColor: "#a2cf6e"
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  }
});

const mapStateToProps = state => {
  return {
    selectedOrder: state.orders.selectedOrder,
    selectedField: state.orders.selectedField,
    selectedDriver: state.orders.selectedDriver,
    drivers: state.drivers.drivers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ordersSimpleEditorClose: () =>
      dispatch(actionCreators.ordersSimpleEditorClose()),
    driversModalOpen: payload =>
      dispatch(actionCreators.driversModalOpen(payload)),
    driversFetch: () => dispatch(actionCreators.driversFetch())
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSnackbar(SimpleEditor))
);

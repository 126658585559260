import axios from 'axios';
import moment from 'moment';

export function create(payload) {
    return axios.post('companies', payload);
};

export function getToday() {
    let now = new Date();

    let params = {
        startDate: moment(now).format('YYYY-MM-DD'),
        endDate: moment(now).format('YYYY-MM-DD')
    };

    return axios.get('orders', {params: params})
        .then(response => response.data.orders);
};

export function getTomorrow() {
    let now = new Date();

    let params = {
        startDate: moment(now).add(1, 'day').format('YYYY-MM-DD'),
        endDate: moment(now).add(1, 'day').format('YYYY-MM-DD')
    };

    return axios.get('orders', {params: params})
        .then(response => response.data.orders);
};

export function update(companyId, payload) {
    return axios.put('companies/' + companyId, payload);
};

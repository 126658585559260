import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";

import * as actionCreators from "../../store/actions/index";

import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import Icon from "@material-ui/core/Icon";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Fab from "@material-ui/core/Fab";

import DateInput from "../DateInput";

import HolidaysRow from "./HolidaysRow";

class Holidays extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      formOpen: false,
      formBusy: false,
      newHolidays: { start: "", end: "", UserId: "" },
      holidays: [],
      filter: ""
    };
  }

  componentDidMount() {
    this.props.driversFetch();
    this.loadHolidays();
  }

  loadHolidays = () => {
    axios.get(`drivers/holidays`).then(res => {
      const holidays = res.data.holidays;
      this.setState({ holidays });
    });
  };

  handleFilterChange = e => {
    let { value } = e.target;
    this.setState({ filter: parseInt(value) });
  };

  findDay = (dayINeed, nextWeek = false) => {
    const today = moment().isoWeekday();

    // if we haven't yet passed the day of the week that I need:
    if (today <= dayINeed && !nextWeek) {
      // then just give me this week's instance of that day
      return moment().isoWeekday(dayINeed);
    } else {
      // otherwise, give me *next week's* instance of that same day
      return moment()
        .add(1, "weeks")
        .isoWeekday(dayINeed);
    }
  };

  handleFormOpen = () => {
    let friday = this.findDay(5);
    friday.set("hour", 22).set("minute", 0);

    let monday = this.findDay(1, true);
    monday.set("hour", 2).set("minute", 0);

    let firstDriver = this.props.drivers[0] ? this.props.drivers[0].id : "";

    this.setState({
      formOpen: true,
      newHolidays: {
        start: friday.format(),
        end: monday.format(),
        UserId: this.state.filter ? this.state.filter : firstDriver
      }
    });
  };

  handleFormClose = () => {
    this.setState({ formOpen: false });
  };

  handleInputChange = (e, a) => {
    let { name, value } = e.target;
    let newHolidays = this.state.newHolidays;

    newHolidays[name] = value;
    this.setState({
      newHolidays
    });
  };

  handleDateChange = (date, fieldName) => {
    let newHolidays = this.state.newHolidays;

    newHolidays[fieldName] = date;
    this.setState({ newHolidays });
  };

  openEditHandler = data => {
    let newHolidays = { ...data };

    this.setState({ newHolidays, formOpen: true });
  };

  holidaysSave = () => {
    if (this.state.newHolidays.id) {
      this.holidaysUpdate();
    } else {
      this.holidaysCreate();
    }
  };

  holidaysUpdate = () => {
    this.setState({ formBusy: true });
    let holiday = this.state.newHolidays;

    axios
      .put("drivers/holidays/" + holiday.id, holiday)
      .then(response => {
        this.loadHolidays();
        this.setState({ formBusy: false, formOpen: false });
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
        this.setState({ formBusy: false });
      });
  };

  holidaysCreate = () => {
    let holidays = this.state.newHolidays;

    this.setState({ formBusy: true });
    axios
      .post(`drivers/holidays`, holidays)
      .then(response => {
        this.loadHolidays();
        this.setState({ formBusy: false, formOpen: false });
      })
      .catch(err => {
        this.setState({ formBusy: false });
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  holidaysDelete = data => {
    axios
      .delete("drivers/holidays/" + data.id)
      .then(response => {
        let holidays = this.state.holidays;
        let pos = holidays.map(e => e.id).indexOf(data.id);

        delete holidays[pos];
        this.setState({ holidays });
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  render() {
    let { formBusy, newHolidays, holidays, filter } = this.state;
    const { classes } = this.props;

    if (filter) {
      holidays = holidays.filter(h => h.UserId === filter);
    }

    let form = (
      <Dialog
        open={this.state.formOpen}
        onClose={this.handleFormClose}
        aria-labelledby="form-dialog-add"
        classes={{ paperScrollPaper: classes.overflow }}
      >
        <ValidatorForm
          ref="form"
          onSubmit={this.holidaysSave}
          className="dialogModal"
        >
          <DialogContent className={classes.overflow}>
            <FormControl
              className={classes.formControl}
              style={{ width: "100%" }}
            >
              <InputLabel htmlFor="kto">Kierowca</InputLabel>
              <Select
                native
                value={newHolidays.UserId}
                onChange={this.handleInputChange}
                id="kto"
                name="UserId"
                autoWidth={true}
              >
                {this.props.drivers.map(driver => (
                  <option key={"driverS" + driver.id} value={driver.id}>
                    {driver.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <div className={classes.flex} style={{ marginTop: "1em" }}>
              <DateInput
                inline={true}
                onChange={date => this.handleDateChange(date, "start")}
                selectedDate={newHolidays.start}
              />
              <Icon>arrow_right_alt</Icon>
              <DateInput
                inline={true}
                onChange={date => this.handleDateChange(date, "end")}
                selectedDate={newHolidays.end}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleFormClose} color="primary">
              Anuluj
            </Button>
            <div className={classes.relative}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={formBusy}
              >
                Zapisz
              </Button>
              {formBusy && (
                <CircularProgress
                  size={24}
                  color="secondary"
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );

    let search = (
      <div className={classes.flex}>
        <FormControl>
          <InputLabel htmlFor="driverFilter">Kierowca</InputLabel>
          <Select
            native
            value={this.state.filter}
            onChange={this.handleFilterChange}
            name="DriverId"
            autoWidth={true}
            inputProps={{
              name: "DriverId",
              id: "driverFilter"
            }}
          >
            <option value={0} />
            {this.props.drivers.map(driver => (
              <option key={"driverS" + driver.id} value={driver.id}>
                {driver.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
    );

    return (
      <div className="padding15vw">
        <Fab
          color="secondary"
          aria-label="dodaj"
          className={classes.addButton}
          onClick={this.handleFormOpen}
        >
          <AddIcon />
        </Fab>
        {form}
        {search}
        <Paper className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Kto</TableCell>
                <TableCell>Od</TableCell>
                <TableCell>Do</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {holidays.map(data => {
                return (
                  <HolidaysRow
                    key={data.id}
                    data={data}
                    onEdit={() => this.openEditHandler(data)}
                    onDelete={() => this.holidaysDelete(data)}
                  />
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  addButton: {
    position: "fixed",
    top: "68px",
    right: "20px",
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      top: "auto",
      bottom: "20px"
    }
  },
  roleInput: {
    marginTop: "20px",
    marginBottom: "20px"
  },
  relative: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  flexEnd: {
    marginLeft: "auto"
  }
});

const mapStateToProps = state => {
  return {
    drivers: [...state.drivers.drivers]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    driversFetch: () => dispatch(actionCreators.driversFetch())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withStyles(styles)(Holidays)));

export const AUTH_SET = "AUTH_SET";
export const AUTH_CLEAR = "AUTH_CLEAR";

export const COMPANIES_SAVE = "COMPANIES_SAVE";
export const COMPANIES_FETCH = "COMPANIES_FETCH";
export const COMPANIES_UPDATE = "COMPANIES_UPDATE";

export const DRIVERS_FETCH = "DRIVERS_FETCH";
export const DRIVERS_SAVE = "DRIVERS_SAVE";
export const DRIVERS_MODAL_OPEN = "DRIVERS_MODAL_OPEN";
export const DRIVERS_MODAL_CLOSE = "DRIVERS_MODAL_CLOSE";

export const MAP_CLOSE = "MAP_CLOSE";
export const MAP_OPEN = "MAP_OPEN";

export const SIMPLEDIALOG_CLOSE = "SIMPLEDIALOG_CLOSE";
export const SIMPLEDIALOG_OPEN = "SIMPLEDIALOG_OPEN";

export const ORDERS_CHANGEVIEW = "ORDERS_CHANGEVIEW";
export const ORDERS_TODAY_FETCH = "ORDERS_TODAY_FETCH";
export const ORDERS_TOMORROW_FETCH = "ORDERS_TOMORROW_FETCH";
export const ORDERS_TODAY_SAVE = "ORDERS_TODAY_SAVE";
export const ORDERS_TOMORROW_SAVE = "ORDERS_TOMORROW_SAVE";
export const ORDERS_UPDATE = "ORDERS_UPDATE";
export const ORDERS_UPDATE_DELETE = "ORDERS_UPDATE_DELETE";
export const ORDERS_HISTORY_OPEN = "ORDERS_HISTORY_OPEN";
export const ORDERS_HISTORY_CLOSE = "ORDERS_HISTORY_CLOSE";
export const ORDERS_FORM_OPEN = "ORDERS_FORM_OPEN";
export const ORDERS_FORM_CLOSE = "ORDERS_FORM_CLOSE";
export const ORDERS_DRIVER_SAVE = "ORDERS_DRIVER_SAVE";
export const ORDERS_SIMPLE_EDITOR_OPEN = "ORDERS_SIMPLE_EDITOR_OPEN";
export const ORDERS_SIMPLE_EDITOR_CLOSE = "ORDERS_SIMPLE_EDITOR_CLOSE";
export const ORDERS_ARCHIVE_DATES_SAVE = "ORDERS_ARCHIVE_DATES_SAVE";
export const ORDERS_ARCHIVE_SAVE = "ORDERS_ARCHIVE_SAVE";
export const ORDERS_FILTERS_SAVE = "ORDERS_FILTERS_SAVE";

import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import * as actionCreators from '../../store/actions/index';

import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';

import OrdersTable from './OrdersTable';

class ChangesHistory extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      orders: []
    };

    if (this.props.open && this.props.selectedOrder) {
      this.loadChangesHistory(this.props.selectedOrder);
    }
  }

  componentWillReceiveProps({open, selectedOrder}) {
    if (open && selectedOrder) {
        this.loadChangesHistory(selectedOrder);
    }
  }

  loadChangesHistory = (selectedOrder) => {
    if(!selectedOrder) return;
    axios.get(`orders/` + selectedOrder.id + '/history')
      .then(res => {
        let orders = res.data.orders;
        orders.push(this.props.selectedOrder);
        this.setState({orders: orders});
      })
      .catch(err => {
        this.props.enqueueSnackbar('Błąd! Spróbuj później', {variant: 'error'});
      });
  };


  render() {
    const {classes} = this.props;

    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.changesHistoryClose}
      >
        <AppBar position="fixed">
          <Toolbar variant="dense">
            <div className={classes.flexGrow}></div>
            <Button variant="contained" color="secondary" onClick={this.props.close} aria-label="Zamknij">
              <Icon>close</Icon>
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.barMargin}>
          <OrdersTable orders={this.state.orders} showEditor={true} disableEditing={true}/>
        </div>
      </Dialog>
    );
  };

}

const styles = {
  barMargin: {
    margin: '80px auto 20px auto',
    padding: '0 5vw'
  },
  flexGrow: {
    flexGrow: 1
  }
};

const mapStateToProps = state => {
  return {
      open: state.orders.historyOpen,
      selectedOrder: {...state.orders.selectedOrder}
  }
};

const mapDispatchToProps = dispatch => {
  return {
      close: () => dispatch(actionCreators.ordersHistoryClose())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withStyles(styles)(ChangesHistory)))
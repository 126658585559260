import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import * as actionCreators from "../../store/actions/index";
import axios from "axios";
import _ from "lodash";

import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";

import moment from "moment";
import OrdersTable from "./OrdersTable";

moment.locale("pl");

class History extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      orders: [],
      filters: {
        CompanyId: "",
        OrdererId: "",
        DriverId: "",
        driverNumber: "",
        costCenter: ""
      },
      pagination: {
        page: 1,
        totalPages: 1
      },
      users: []
    };
  }

  componentDidMount() {
    this.props.driversFetch();
    this.props.companiesFetch();
    this.props.ordersFiltersSave({
      CompanyId: "",
      OrdererId: "",
      DriverId: "",
      driverNumber: "",
      costCenter: ""
    });
    this.loadOrders();

    document.addEventListener("orderChanged", this.handleOrderChange, false);
  }

  componentWillUnmount() {
    document.removeEventListener("orderChanged", this.handleOrderChange, false);
  }

  handleOrderChange = e => {
    let order = e.detail.order;
    let action = e.detail.action;

    if (!order) {
      this.loadOrders(true);
      return;
    }

    let orders = [...this.state.orders];
    let index = orders.findIndex(o => o.id === order.id);

    if (index >= 0) {
      if (action === "delete") {
        orders.splice(index, 1);
        this.setState({ orders });
        return;
      }

      if (action === "driverAdd") {
        let driverId = parseInt(order.DriverId);
        let driver = this.props.drivers.find(d => d.id === driverId);

        order = { ...orders[index] };
        order.DriverId = driverId;
        order.driver = driver;
      }

      if (action === "driverRemove") {
        order = { ...orders[index] };
        delete order.driver;
        order.DriverId = null;
      }

      orders[index] = { ...order };
      this.setState({ orders });
    } else {
      this.loadOrders();
    }
  };

  loadOrders = () => {
    this.setState({ loading: true });

    let params = {
      page: this.state.pagination.page
    };

    if (this.state.filters.CompanyId)
      params.CompanyId = this.state.filters.CompanyId;
    if (this.state.filters.OrdererId)
      params.OrdererId = this.state.filters.OrdererId;
    if (this.state.filters.DriverId)
      params.DriverId = this.state.filters.DriverId;
    if (this.state.filters.driverNumber)
      params.driverNumber = this.state.filters.driverNumber;
    if (this.state.filters.costCenter)
      params.costCenter = this.state.filters.costCenter;

    axios
      .get("orders/paginated", { params: params })
      .then(res => {
        let orders = res.data.orders;
        let pagination = { ...this.state.pagination };
        pagination.totalPages = Math.ceil(res.data.total / 100)
          ? Math.ceil(res.data.total / 100)
          : 1;
        window.scrollTo(0, 0);
        this.setState({ orders, loading: false, pagination });
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Nie udało się pobrać zleceń";

        this.setState({ loading: false });
        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  debouncedLoadOrders = _.debounce(this.loadOrders, 500, {
    maxWait: 3000
  });

  changePage = num => {
    let pagination = { ...this.state.pagination };
    pagination.page = pagination.page + num;

    this.setState({ pagination }, this.loadOrders);
  };

  loadUsers = () => {
    let companyId = this.state.filters.CompanyId;

    axios
      .get(`users/getcompanyusers/` + companyId)
      .then(res => {
        let users = res.data.users;
        this.setState({ users });
      })
      .catch(err => {
        this.props.enqueueSnackbar(
          "Błąd! Nie udało się załadować listy zamawiających",
          { variant: "error" }
        );
      });
  };

  handleFilterChange = (e, a) => {
    let { name, value } = e.target;
    let filters = this.state.filters;

    if (name === "CompanyId" && !value) {
      filters.OrdererId = "";
    }

    filters[name] = value;

    let pagination = {
      page: 1,
      totalPages: 1
    };

    this.props.ordersFiltersSave({ ...filters });

    this.setState({ filters, pagination }, () => {
      if (name === "costCenter" || name === "driverNumber") {
        this.debouncedLoadOrders();
      } else {
        this.loadOrders();
      }

      if (name === "CompanyId" && value) {
        this.loadUsers();
      }
    });
  };

  clearFilters = () => {
    let pagination = {
      page: 1,
      totalPages: 1
    };

    let filters = {
      CompanyId: "",
      OrdererId: "",
      DriverId: "",
      driverNumber: "",
      costCenter: ""
    };

    this.setState(
      {
        pagination,
        filters
      },
      () => {
        this.loadOrders();
        this.props.ordersFiltersSave({
          ...filters
        });
      }
    );
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    let header = (
      <header className={classes.flex} style={{ paddingRight: "90px" }}>
        <div className={classes.flexGrow} />
        <div>
          <TextField
            className={classes.selectContainer}
            label="CC/MPK/Karta"
            value={this.state.filters.costCenter}
            name="costCenter"
            onChange={this.handleFilterChange}
            margin="none"
          />

          <FormControl className={classes.selectContainer}>
            <InputLabel htmlFor="driverFilter">Kierowca</InputLabel>
            <Select
              native
              value={this.state.filters.DriverId}
              onChange={this.handleFilterChange}
              name="DriverId"
              autoWidth={true}
              inputProps={{
                name: "DriverId",
                id: "driverFilter"
              }}
              style={{ width: "150px" }}
            >
              <option value={""} />
              {this.props.drivers.map(driver => (
                <option key={"driverS" + driver.id} value={driver.id}>
                  {driver.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <TextField
            className={classes.selectContainer}
            label="Nr. Taxi"
            value={this.state.filters.driverNumber}
            name="driverNumber"
            onChange={this.handleFilterChange}
            margin="none"
          />

          <FormControl className={classes.selectContainer}>
            <InputLabel htmlFor="companyFilter">Firma</InputLabel>
            <Select
              native
              value={this.state.filters.CompanyId}
              onChange={this.handleFilterChange}
              name="CompanyId"
              autoWidth={true}
              inputProps={{
                name: "CompanyId",
                id: "companyFilter"
              }}
              style={{ width: "150px" }}
            >
              <option value={""} />
              {this.props.companies.map(company => (
                <option key={"companyS" + company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </Select>
          </FormControl>
          {this.state.filters.CompanyId ? (
            <FormControl className={classes.selectContainer}>
              <InputLabel htmlFor="driverFilter">Zamawiający</InputLabel>
              <Select
                native
                value={this.state.filters.OrdererId}
                onChange={this.handleFilterChange}
                name="OrdererId"
                autoWidth={true}
                inputProps={{
                  name: "OrdererId",
                  id: "driverFilter"
                }}
                style={{ width: "150px" }}
              >
                <option value={""} />
                {this.state.users.map(u => (
                  <option key={"ordererS" + u.id} value={u.id}>
                    {u.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          ) : null}
          {Object.values(this.state.filters).join("").length ? (
            <Tooltip
              title="Wyczyść"
              placement="top"
              style={{ marginLeft: "1em" }}
            >
              <IconButton
                variant="contained"
                color="secondary"
                style={{
                  marginTop: "10px"
                }}
                onClick={this.clearFilters}
              >
                <Icon>close</Icon>
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </header>
    );

    let loader = (
      <div className={classes.loader}>
        <CircularProgress disableShrink={true} style={{ margin: "auto" }} />
      </div>
    );

    return (
      <div>
        {loading && loader}

        {header}
        <OrdersTable
          orders={this.state.orders}
          startDate={moment()
            .add("1", "year")
            .format()}
        />
        <div className={classes.flex}>
          <div className={classes.flexGrow} />
          {this.state.pagination.page === 1 ? null : (
            <Icon
              onClick={() => this.changePage(-1)}
              className={classes.pointer}
            >
              chevron_left
            </Icon>
          )}
          <span className={classes.paginationNumbers}>
            {this.state.pagination.page} / {this.state.pagination.totalPages}
          </span>
          {this.state.pagination.page ===
          this.state.pagination.totalPages ? null : (
            <Icon
              onClick={() => this.changePage(1)}
              className={classes.pointer}
            >
              chevron_right
            </Icon>
          )}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    overflow: "visible"
  },
  addButton: {
    position: "fixed",
    top: "68px",
    right: "20px",
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      top: "auto",
      bottom: "20px"
    }
  },
  roleInput: {
    marginTop: "20px",
    marginBottom: "20px"
  },
  relative: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  dialog: {
    minWidth: "60vw"
  },
  overflow: {
    overflow: "visible"
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  flexGrow: {
    flexGrow: 1
  },
  selectContainer: {
    minWidth: "120px",
    marginRight: "1rem"
  },
  date: {
    cursor: "pointer",
    display: "flex",
    padding: ".3em",
    borderRadius: "2px",
    alignItems: "center",
    marginLeft: "21px",
    marginRight: "21px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },
  loader: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255, 0.8)",
    zIndex: 2
  },
  paginationNumbers: {
    fontSize: "0.8em",
    lineHeight: "1.8em"
  },
  pointer: {
    cursor: "pointer"
  }
});

const mapStateToProps = state => {
  return {
    drivers: state.drivers.drivers,
    companies: state.companies.companies,
    filters: state.orders.filters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    driversFetch: () => dispatch(actionCreators.driversFetch()),
    companiesFetch: () => dispatch(actionCreators.companiesFetch()),
    ordersFiltersSave: payload =>
      dispatch(actionCreators.ordersFiltersSave(payload))
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSnackbar(History))
);

import React, { Component } from "react";
import axios from "axios";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withSnackbar } from "notistack";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon";
import Fab from "@material-ui/core/Fab";
import Select from "@material-ui/core/Select";

import PricingRow from "./PricingRow";
import CompanySelect from "./CompanySelect";

class Pricings extends Component {
  state = {
    formOpen: false,
    formBusy: false,
    newPricing: {
      city1: "",
      city2: "",
      paymentAmount: null,
      CompanyId: "",
      type: "Osobowy"
    },
    pricings: [],
    selectedCompany: ""
  };

  setCompany = company => {
    this.setState({ selectedCompany: company.id }, this.loadPricings);
  };

  handleFormOpen = () => {
    this.setState({
      formOpen: true,
      newPricing: {
        city1: "",
        city2: "",
        paymentAmount: null,
        CompanyId: "",
        type: "Osobowy"
      }
    });
  };

  loadPricings = () => {
    axios.get(`pricings/` + this.state.selectedCompany).then(res => {
      const pricings = res.data.pricings;
      this.setState({ pricings });
    });
  };

  handleFormClose = () => {
    this.setState({ formOpen: false });
  };

  handleInputChange = (e, a) => {
    let { name, value } = e.target;
    let newPricing = this.state.newPricing;

    if (name === "city1" || name === "city2") {
      value = value
        .toLowerCase()
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    }

    newPricing[name] = value;
    this.setState({ newPricing });
  };

  pricingSave = () => {
    if (this.state.newPricing.id) {
      this.pricingUpdate();
    } else {
      this.pricingCreate();
    }
  };

  pricingUpdate = () => {
    this.setState({ formBusy: true });
    let pricing = { ...this.state.newPricing };

    axios
      .put("pricings/" + pricing.id, pricing)
      .then(response => {
        let pricings = this.state.pricings;
        let newPricing = response.data.pricing;
        let pos = pricings.map(e => e.id).indexOf(newPricing.id);

        pricings[pos] = newPricing;
        this.setState({ pricings, formBusy: false, formOpen: false });
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
        this.setState({ formBusy: false });
      });
  };

  pricingCreate = () => {
    let pricing = { ...this.state.newPricing };

    pricing.CompanyId = this.state.selectedCompany;
    this.setState({ formBusy: true });
    axios
      .post(`pricings`, pricing)
      .then(res => {
        let pricings = this.state.pricings;

        pricings.push(res.data.pricing);
        this.setState({ pricings, formBusy: false, formOpen: false });
      })
      .catch(err => {
        this.setState({ formBusy: false });
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  openEditHandler = data => {
    let newPricing = { ...data };

    this.setState({ newPricing, formOpen: true });
  };

  deleteHandler = data => {
    axios
      .delete("pricings/" + data.id)
      .then(response => {
        let pricings = this.state.pricings;
        let pos = pricings.map(e => e.id).indexOf(data.id);

        delete pricings[pos];
        this.setState({ pricings });
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  handleAddressChange = (address, fieldName) => {
    let newPricing = this.state.newPricing;

    newPricing[fieldName] = address.address;
    this.setState({ newPricing });
  };

  render() {
    let { pricings, formBusy, newPricing, selectedCompany } = this.state;
    const { classes } = this.props;

    return (
      <div className="padding15vw">
        {selectedCompany !== "" ? (
          <Fab
            color="secondary"
            aria-label="dodaj"
            className={classes.addButton}
            onClick={this.handleFormOpen}
          >
            <AddIcon />
          </Fab>
        ) : null}
        <Dialog
          open={this.state.formOpen}
          onClose={this.handleFormClose}
          aria-labelledby="form-dialog-add"
          classes={{ paperScrollPaper: classes.overflow }}
          disableBackdropClick={true}
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.pricingSave}
            className="dialogModal"
          >
            <DialogContent className={classes.overflow}>
              <TextValidator
                autoFocus
                margin="dense"
                label="Pierwsze Miasto"
                name="city1"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newPricing.city1}
                validators={["required"]}
                errorMessages={["To pole jest wymagane"]}
              />

              <TextValidator
                margin="dense"
                label="Drugie Miasto"
                name="city2"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newPricing.city2}
                validators={["required"]}
                errorMessages={["To pole jest wymagane"]}
              />

              <Select
                style={{ marginTop: "20px" }}
                native
                value={newPricing.type ? newPricing.type : ""}
                onChange={this.handleInputChange}
                fullWidth
                label="Typ"
                inputProps={{
                  name: "type",
                  id: "type"
                }}
              >
                <option value="Osobowy">Osobowy</option>
                <option value="Van">Van</option>
                <option value="Vip">Vip</option>
              </Select>

              <TextValidator
                margin="dense"
                label="Cena"
                name="paymentAmount"
                type="number"
                step="0.01"
                onChange={this.handleInputChange}
                fullWidth
                value={newPricing.paymentAmount ? newPricing.paymentAmount : ""}
                validators={["required"]}
                errorMessages={["To pole jest wymagane"]}
                InputProps={{
                  type: "number",
                  step: "0.01"
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleFormClose} color="primary">
                Anuluj
              </Button>
              <div className={classes.relative}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={formBusy}
                >
                  Zapisz
                </Button>
                {formBusy && (
                  <CircularProgress
                    size={24}
                    color="secondary"
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
        <CompanySelect selected={company => this.setCompany(company)} />
        {selectedCompany !== "" ? (
          <Paper className={classes.root}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Trasa</TableCell>
                  <TableCell>Rodzaj</TableCell>
                  <TableCell>Cena</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {pricings.map(data => {
                  return (
                    <PricingRow
                      key={data.id}
                      data={data}
                      onEdit={() => this.openEditHandler(data)}
                      onDelete={() => this.deleteHandler(data)}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        ) : (
          <div className={classes.flexCenter}>
            <Icon className={classes.hugeIcon}>error_outline</Icon>
            <p className={classes.backgroundText}>Wybierz firmę</p>
          </div>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  addButton: {
    position: "fixed",
    top: "68px",
    right: "20px",
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      top: "auto",
      bottom: "20px"
    }
  },
  roleInput: {
    marginTop: "20px",
    marginBottom: "20px"
  },
  relative: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  dialog: {
    minWidth: "60vw"
  },
  overflow: {
    overflow: "visible"
  },
  flexCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "60vh",
    justifyContent: "center",
    color: "#cccaca"
  },
  hugeIcon: {
    fontSize: "6rem"
  },
  backgroundText: {
    textTransform: "uppercase"
  }
});

export default withSnackbar(withStyles(styles)(Pricings));

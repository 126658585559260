import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import * as actionCreators from '../../store/actions/index';

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

import Manage from './Manage';
import Archive from './Archive';
import History from './History';
import ChangesHistory from './ChangesHistory';
import OrderForm from './OrderForm';
import SimpleEditor from './SimpleEditor';
class Orders extends Component {

  handleFormOpen = () => {
    this.props.ordersFormOpen();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.props.historyOpen && <ChangesHistory />}
        {this.props.activeView === 'manage' && <Manage />}
        {this.props.activeView === 'daybyday' && <Archive />}
        {this.props.activeView === 'history' && <History />}
        <Fab color="secondary" aria-label="dodaj"
          className={classes.addButton}
          onClick={this.handleFormOpen}>
          <AddIcon />
        </Fab>
        {this.props.formOpen && <OrderForm />}
        {this.props.simpleEditorOpen && <SimpleEditor />}
      </div>
    );
  }
}


const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing.unit * 3,
    overflow: 'visible',
  },
  addButton: {
    position: 'fixed',
    top: '68px',
    right: '20px',
    zIndex: 99,
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      bottom: '20px'
    },
  },
});

const mapStateToProps = state => {
  return {
    activeView: state.orders.activeView,
    formOpen: state.orders.formOpen,
    historyOpen: state.orders.historyOpen,
    simpleEditorOpen: state.orders.simpleEditorOpen
  }
};

const mapDispatchToProps = dispatch => {
  return {
    ordersFormOpen: (order) => dispatch(actionCreators.ordersFormOpen(order))
  }
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Orders)));

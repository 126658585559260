import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import * as actionCreators from "../../store/actions/index";
import axios from "axios";
import _ from "lodash";
import fileSaver from "file-saver";

import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import pl from "react-date-range/dist/locale/pl";
import moment from "moment";
import OrdersTable from "./OrdersTable";

moment.locale("pl");

const convertBase64toArrayBuffer = function (base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

class Archive extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      pickerOpen: false,
      tempDateRange: {
        startDate: moment(this.props.dateRange.startDate).toDate(),
        endDate: moment(this.props.dateRange.endDate).toDate(),
        key: "selection"
      },
      dateRange: {
        startDate: null,
        endDate: null
      },
      users: [],
      filters: {
        CompanyId: "",
        OrdererId: "",
        DriverId: "",
        driverNumber: "",
        costCenter: "",
        clientName: ""
      }
    };
  }

  componentWillReceiveProps({ dateRange }) {
    if (
      dateRange.startDate !== this.state.dateRange.startDate ||
      dateRange.endDate !== this.state.dateRange.endDate
    ) {
      this.setState(
        {
          tempDateRange: {
            startDate: moment(dateRange.startDate).toDate(),
            endDate: moment(dateRange.endDate).toDate(),
            key: "selection"
          },
          dateRange: dateRange
        },
        this.loadOrders
      );
    }
  }

  componentDidMount() {
    this.props.driversFetch();
    this.props.companiesFetch();
    this.props.ordersFiltersSave({
      CompanyId: "",
      OrdererId: "",
      DriverId: "",
      driverNumber: "",
      costCenter: "",
      clientName: ""
    });
  }

  loadOrders = () => {
    this.setState({ loading: true });

    let params = {
      startDate: this.props.dateRange.startDate,
      endDate: this.props.dateRange.endDate
    };

    if (this.state.filters.CompanyId)
      params.CompanyId = this.state.filters.CompanyId;
    if (this.state.filters.OrdererId)
      params.OrdererId = this.state.filters.OrdererId;
    if (this.state.filters.DriverId)
      params.DriverId = this.state.filters.DriverId;
    if (this.state.filters.driverNumber)
      params.driverNumber = this.state.filters.driverNumber;
    if (this.state.filters.costCenter)
      params.costCenter = this.state.filters.costCenter;
    if (this.state.filters.clientName)
      params.clientName = this.state.filters.clientName;

    axios
      .get("orders", { params: params })
      .then(res => {
        let orders = res.data.orders;
        this.props.ordersArchiveSave(orders);
        this.setState({ loading: false });
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Nie udało się pobrać zleceń";

        this.setState({ loading: false });
        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  debouncedLoadOrders = _.debounce(this.loadOrders, 500, {
    maxWait: 3000
  });

  getCSV = () => {
    let params = {
      startDate: this.props.dateRange.startDate,
      endDate: this.props.dateRange.endDate
    };

    if (this.state.filters.CompanyId)
      params.CompanyId = this.state.filters.CompanyId;
    if (this.state.filters.OrdererId)
      params.OrdererId = this.state.filters.OrdererId;
    if (this.state.filters.DriverId)
      params.DriverId = this.state.filters.DriverId;
    if (this.state.filters.driverNumber)
      params.driverNumber = this.state.filters.driverNumber;
    if (this.state.filters.costCenter)
      params.costCenter = this.state.filters.costCenter;

    axios
      .get("orders/xlsx", {
        params: params,
        responseType: "text",
        headers: { "Content-Type": "application/octet-stream" }
      })
      .then(res => {
        let blob = new Blob([convertBase64toArrayBuffer(res.data)]);
        fileSaver.saveAs(blob, "fly.xlsx");
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Nie udało się pobrać zleceń";

        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  loadUsers = () => {
    let companyId = this.state.filters.CompanyId;

    axios
      .get(`users/getcompanyusers/` + companyId)
      .then(res => {
        let users = res.data.users;
        this.setState({ users });
      })
      .catch(err => {
        this.props.enqueueSnackbar(
          "Błąd! Nie udało się załadować listy zamawiających",
          { variant: "error" }
        );
      });
  };

  changeDay = number => {
    let date = this.state.dateRange.startDate;
    date = moment(date)
      .add(number, "day")
      .format("YYYY-MM-DD");

    let newDateRange = {
      startDate: date,
      endDate: date
    };

    this.setState({ dateRange: newDateRange });
    this.props.ordersArchiveDatesSave(newDateRange);
  };

  togglePicker = () => {
    let newPicker = !this.state.pickerOpen;

    this.setState({ pickerOpen: newPicker });
  };

  handleDateSelect = ranges => {
    let newDate = {
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection"
    };

    this.setState({ tempDateRange: newDate });
  };

  confirmDateRanges = () => {
    let newDate = {
      startDate: moment(this.state.tempDateRange.startDate).format(
        "YYYY-MM-DD"
      ),
      endDate: moment(this.state.tempDateRange.endDate).format("YYYY-MM-DD")
    };

    this.props.ordersArchiveDatesSave(newDate);
    this.togglePicker();
  };

  handleFilterChange = (e, a) => {
    let { name, value } = e.target;
    let filters = this.state.filters;

    if (name === "CompanyId" && !value) {
      filters.OrdererId = "";
    }

    filters[name] = value;

    this.props.ordersFiltersSave({ ...filters });

    this.setState({ filters }, () => {
      if (name === "costCenter" || name === "driverNumber" || name === "clientName") {
        this.debouncedLoadOrders();
      } else {
        this.loadOrders();
      }

      if (name === "CompanyId" && value) {
        this.loadUsers();
      }
    });
  };

  clearFilters = () => {
    let newDate = {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
    };
    this.props.ordersArchiveDatesSave(newDate);

    this.props.ordersFiltersSave({
      CompanyId: "",
      OrdererId: "",
      DriverId: "",
      driverNumber: "",
      costCenter: ""
    });

    this.setState(
      {
        filters: {
          CompanyId: "",
          OrdererId: "",
          DriverId: "",
          driverNumber: "",
          costCenter: ""
        }
      },
      this.loadOrders
    );
  };

  render() {
    const { classes } = this.props;
    const { pickerOpen, dateRange, loading } = this.state;
    let dateRangeOutput =
      dateRange.endDate === dateRange.startDate
        ? moment(dateRange.startDate).format("dddd, DD.MM")
        : moment(dateRange.startDate).format("DD.MM") +
        " - " +
        moment(dateRange.endDate).format("DD.MM");
    let isOneDay = dateRange.endDate === dateRange.startDate ? true : false;

    let header = (
      <header className={classes.flex} style={{ paddingRight: "90px" }}>
        <div className={classes.flexCenter}>
          {isOneDay && (
            <Icon
              onClick={() => this.changeDay(-1)}
              style={{ cursor: "pointer" }}
            >
              keyboard_arrow_left
            </Icon>
          )}
          <div className={classes.date} onClick={this.togglePicker}>
            <Typography variant="h5" gutterBottom style={{ marginBottom: 0 }}>
              {dateRangeOutput}
            </Typography>
          </div>
          {isOneDay && (
            <Icon
              onClick={() => this.changeDay(1)}
              style={{ cursor: "pointer" }}
            >
              keyboard_arrow_right
            </Icon>
          )}
        </div>
        <div className={classes.flexGrow} />
        <div>
          <TextField
            className={classes.inputContainer}
            label="Pasażer"
            value={this.state.filters.clientName}
            name="clientName"
            onChange={this.handleFilterChange}
            margin="none"
          />

          <TextField
            className={classes.inputContainer}
            label="CC/MPK/Karta"
            value={this.state.filters.costCenter}
            name="costCenter"
            onChange={this.handleFilterChange}
            margin="none"
          />

          <FormControl className={classes.selectContainer}>
            <InputLabel htmlFor="driverFilter">Kierowca</InputLabel>
            <Select
              native
              value={this.state.filters.DriverId}
              onChange={this.handleFilterChange}
              name="DriverId"
              autoWidth={true}
              inputProps={{
                name: "DriverId",
                id: "driverFilter"
              }}
              style={{ width: "150px" }}
            >
              <option value={""} />
              {this.props.drivers.map(driver => (
                <option key={"driverS" + driver.id} value={driver.id}>
                  {driver.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <TextField
            className={classes.inputContainer}
            label="Nr. Taxi"
            value={this.state.filters.driverNumber}
            name="driverNumber"
            onChange={this.handleFilterChange}
            margin="none"
          />

          <FormControl className={classes.selectContainer}>
            <InputLabel htmlFor="companyFilter">Firma</InputLabel>
            <Select
              native
              value={this.state.filters.CompanyId}
              onChange={this.handleFilterChange}
              name="CompanyId"
              autoWidth={true}
              inputProps={{
                name: "CompanyId",
                id: "companyFilter"
              }}
              style={{ width: "150px" }}
            >
              <option value={""} />
              {this.props.companies.map(company => (
                <option key={"companyS" + company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </Select>
          </FormControl>
          {this.state.filters.CompanyId ? (
            <FormControl className={classes.selectContainer}>
              <InputLabel htmlFor="driverFilter">Zamawiający</InputLabel>
              <Select
                native
                value={this.state.filters.OrdererId}
                onChange={this.handleFilterChange}
                name="OrdererId"
                autoWidth={true}
                inputProps={{
                  name: "OrdererId",
                  id: "driverFilter"
                }}
                style={{ width: "150px" }}
              >
                <option value={""} />
                {this.state.users.map(u => (
                  <option key={"ordererS" + u.id} value={u.id}>
                    {u.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          ) : null}
          {Object.values(this.state.filters).join("").length ? (
            <Tooltip
              title="Wyczyść"
              placement="top"
              style={{ marginLeft: "1em" }}
            >
              <IconButton
                variant="contained"
                color="secondary"
                style={{
                  marginTop: "10px"
                }}
                onClick={this.clearFilters}
              >
                <Icon>close</Icon>
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title="Excell" placement="top" style={{ marginLeft: "1em" }}>
            <IconButton onClick={this.getCSV}>
              <Icon>assignment_returned</Icon>
            </IconButton>
          </Tooltip>
        </div>
      </header>
    );

    let picker = null;

    if (pickerOpen) {
      picker = (
        <Dialog open={true} onClose={this.togglePicker}>
          <DialogContent>
            <DateRange
              locale={pl}
              ranges={[this.state.tempDateRange]}
              onChange={this.handleDateSelect}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.togglePicker}>Anuluj</Button>
            <Button onClick={this.confirmDateRanges} color="primary">
              Zastosuj
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    let loader = (
      <div className={classes.loader}>
        <CircularProgress disableShrink={true} style={{ margin: "auto" }} />
      </div>
    );

    let filteredOrders = this.props.ordersArchive;

    return (
      <div>
        {loading && loader}
        {picker}
        {header}
        <OrdersTable
          orders={filteredOrders}
          startDate={this.state.dateRange.startDate}
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    overflow: "visible"
  },
  addButton: {
    position: "fixed",
    top: "68px",
    right: "20px",
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      top: "auto",
      bottom: "20px"
    }
  },
  roleInput: {
    marginTop: "20px",
    marginBottom: "20px"
  },
  relative: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  dialog: {
    minWidth: "60vw"
  },
  overflow: {
    overflow: "visible"
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  flexGrow: {
    flexGrow: 1
  },
  selectContainer: {
    minWidth: "120px",
    marginRight: "1rem"
  },
  inputContainer: {
    width: "110px",
    marginRight: "1rem"
  },
  date: {
    cursor: "pointer",
    display: "flex",
    padding: ".3em",
    borderRadius: "2px",
    alignItems: "center",
    marginLeft: "21px",
    marginRight: "21px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },
  loader: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255, 0.8)",
    zIndex: 2
  }
});

const mapStateToProps = state => {
  return {
    ordersArchive: [...state.orders.ordersArchive],
    drivers: state.drivers.drivers,
    companies: state.companies.companies,
    dateRange: state.orders.ordersArchiveDates,
    filters: state.orders.filters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    driversFetch: () => dispatch(actionCreators.driversFetch()),
    companiesFetch: () => dispatch(actionCreators.companiesFetch()),
    ordersArchiveSave: payload =>
      dispatch(actionCreators.ordersArchiveSave(payload)),
    ordersArchiveDatesSave: payload =>
      dispatch(actionCreators.ordersArchiveDatesSave(payload)),
    ordersFiltersSave: payload =>
      dispatch(actionCreators.ordersFiltersSave(payload))
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSnackbar(Archive))
);

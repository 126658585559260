import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import * as actionCreators from "../../store/actions/index";
import _ from "lodash";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import OrdersTable from "./OrdersTable";

class Manage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      freeFilter: "",
      driverFilter: "",
      companyFilter: ""
    };
  }

  componentDidMount() {
    this.props.ordersTodayFetch();
    this.props.ordersTomorrowFetch();
    this.props.driversFetch();
    this.props.companiesFetch();
  }

  handleTabChange = (event, value) => {
    this.setState({ activeTab: value });
  };

  handleInputChange = (e, a) => {
    let { name, value } = e.target;

    this.setState({ [name]: value });
  };

  filtersClear = () => {
    this.setState({ freeFilter: "", companyFilter: "", driverFilter: "" });
  };

  render() {
    const { classes } = this.props;
    const { freeFilter, companyFilter, driverFilter } = this.state;
    let currentList =
      this.state.activeTab === 0
        ? this.props.ordersToday
        : this.props.ordersTomorrow;

    let filteredOrders = currentList;

    // filtering by driver
    if (driverFilter) {
      filteredOrders = _.filter(
        filteredOrders,
        order => order.DriverId === parseInt(driverFilter)
      );
    }

    // filtering by company
    if (companyFilter) {
      filteredOrders = _.filter(
        filteredOrders,
        order => order.CompanyId === parseInt(companyFilter)
      );
    }

    // free filter of orders
    if (freeFilter) {
      let regex = "";
      let filters = freeFilter.toLowerCase().split(" ");

      filters.forEach(filter => {
        regex += "(?=.*" + filter + ")";
      });
      regex += ".*";

      filteredOrders = _.filter(filteredOrders, order => {
        let arr = [
          order.client.name,
          order.client.tel,
          order.costCenter,
          order.from.address,
          order.to.address,
          order.notes,
          order.orderer.name,
          order.paymentAmount
        ];

        order.through.forEach(through => {
          arr.push(through.address);
        });

        let srex = new RegExp(regex, "gm");

        return srex.exec(arr.join(" ").toLowerCase()) !== null;
      });
    }

    let todayLabel = "Dzisiaj";
    let tomorrowLabel = "Jutro";

    let todayConfirmed = this.props.ordersToday.filter(el => !el.confirmed);
    if (todayConfirmed.length) {
      todayLabel = (
        <Badge
          className={classes.padding}
          color="secondary"
          badgeContent={todayConfirmed.length}
        >
          Dzisiaj
        </Badge>
      );
    }

    let tomorrowConfirmed = this.props.ordersTomorrow.filter(
      el => !el.confirmed
    );
    if (tomorrowConfirmed.length) {
      tomorrowLabel = (
        <Badge
          className={classes.padding}
          color="secondary"
          badgeContent={tomorrowConfirmed.length}
        >
          Jutro
        </Badge>
      );
    }

    return (
      <div>
        <header className={classes.flex}>
          <Tabs
            value={this.state.activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleTabChange}
          >
            <Tab label={todayLabel} />
            <Tab label={tomorrowLabel} />
          </Tabs>

          <div className={classes.flexGrow} />

          <FormControl className={classes.selectContainer}>
            <InputLabel htmlFor="driverFilter">Kierowca</InputLabel>
            <Select
              native
              value={this.state.driverFilter}
              onChange={this.handleInputChange}
              name="driverFilter"
              autoWidth={true}
              inputProps={{
                name: "driverFilter",
                id: "driverFilter"
              }}
              style={{ width: "150px" }}
            >
              <option value={""} />
              {this.props.drivers.map(driver => (
                <option key={"driverS" + driver.id} value={driver.id}>
                  {driver.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.selectContainer}>
            <InputLabel htmlFor="companyFilter">Firma</InputLabel>
            <Select
              style={{ width: "150px" }}
              native
              value={this.state.companyFilter}
              onChange={this.handleInputChange}
              name="companyFilter"
              autoWidth={true}
              inputProps={{
                name: "companyFilter",
                id: "companyFilter"
              }}
            >
              <option value={""} />
              {this.props.companies.map(company => (
                <option key={"companyS" + company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Filtruj"
            value={this.state.freeFilter}
            name="freeFilter"
            onChange={this.handleInputChange}
            margin="none"
          />

          {freeFilter.length || driverFilter.length || companyFilter.length ? (
            <Tooltip
              title="Wyczyść"
              placement="top"
              style={{ marginLeft: "1em" }}
            >
              <IconButton
                variant="contained"
                color="secondary"
                style={{
                  marginTop: "10px"
                }}
                onClick={this.filtersClear}
              >
                <Icon>close</Icon>
              </IconButton>
            </Tooltip>
          ) : null}
        </header>
        <OrdersTable orders={filteredOrders} />
      </div>
    );
  }
}

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit * 2
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`
  },
  flex: {
    display: "flex",
    marginRight: "90px"
  },
  flexGrow: {
    flexGrow: 1
  },
  selectContainer: {
    minWidth: "120px",
    marginRight: "1rem"
  }
});

const mapStateToProps = state => {
  return {
    ordersToday: [...state.orders.ordersToday],
    ordersTomorrow: [...state.orders.ordersTomorrow],
    drivers: state.drivers.drivers,
    companies: state.companies.companies
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ordersTodayFetch: () => dispatch(actionCreators.ordersTodayFetch()),
    ordersTomorrowFetch: () => dispatch(actionCreators.ordersTomorrowFetch()),
    driversFetch: () => dispatch(actionCreators.driversFetch()),
    companiesFetch: () => dispatch(actionCreators.companiesFetch())
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSnackbar(Manage))
);

import React from "react";
import moment from "moment";

const Pause = ({ item, currentDate }) => {
  let start = moment(item.start);
  let end = moment(item.end);
  let curDate = moment(currentDate);
  let startOfDay = curDate.startOf("Day");
  let hoursFromStartOfDay = moment.duration(start.diff(startOfDay)).asHours();
  let durationHours =
    curDate.format("DD") !== start.format("DD")
      ? moment.duration(end.diff(startOfDay)).asHours()
      : moment.duration(end.diff(start)).asHours();

  let width =
    (durationHours / 24) * 100 < 100 ? (durationHours / 24) * 100 : 100;
  let left =
    curDate.format("DD") !== start.format("DD")
      ? 0
      : (hoursFromStartOfDay / 24) * 100;

  return (
    <span
      className="time-entry time-entry--pause"
      style={{ width: width + "%", left: left + "%" }}
    >
      <small>Pauza</small>
    </span>
  );
};

export default Pause;

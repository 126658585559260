import React from "react";
import ReactDOM from "react-dom";

import { ErrorBoundary } from "./bugsnag";
import "typeface-roboto";
import "./index.css";
import axios from "axios";
import Main from "./Main";
import { PrivateRoute } from "./components/PrivateRoute";
import Login from "./components/login/Login";
import registerServiceWorker from "./registerServiceWorker";
import { Switch, Route, HashRouter } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";

import { Provider } from "react-redux";
import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import authReducer from "./store/reducers/auth";
import companiesReducer from "./store/reducers/companies";
import driversReducer from "./store/reducers/drivers";
import mapDialogReducer from "./store/reducers/mapDialog";
import simpleDialogReducer from "./store/reducers/simpleDialog";
import ordersReducer from "./store/reducers/orders";
import { rootSaga } from "./store/sagas";
import * as actionCreators from "./store/actions/index";
import { websocketInit } from "./store/websocket";

const rootReducer = combineReducers({
  auth: authReducer,
  companies: companiesReducer,
  drivers: driversReducer,
  mapDialog: mapDialogReducer,
  orders: ordersReducer,
  simpleDialog: simpleDialogReducer,
});

const websocketInitMiddleware = (store) => (next) => (action) => {
  if (action.type === "AUTH_SET") {
    websocketInit(store);
  }
  next(action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, websocketInitMiddleware))
);

sagaMiddleware.run(rootSaga);

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:3000/api/";
} else {
  axios.defaults.baseURL = "/api/";
}

//axios.defaults.baseURL = "https://fly.400.pl/api/";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      sessionStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

const token = sessionStorage.getItem("token");

if (token) {
  axios.defaults.headers.common["Authorization"] = token;
  store.dispatch(
    actionCreators.authSet({ ...JSON.parse(sessionStorage.getItem("user")) })
  );
}

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink,
  },
  typography: {
    useNextVariants: true,
  },
});

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <HashRouter>
          <MuiThemeProvider theme={theme}>
            <Switch>
              <Route exact path="/login" component={Login}></Route>
              <Route
                exact
                path="/logout"
                render={(props) => <Login {...props} logout={true} />}
              ></Route>
              <PrivateRoute path="/" component={Main}></PrivateRoute>
            </Switch>
          </MuiThemeProvider>
        </HashRouter>
      </SnackbarProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);
registerServiceWorker();

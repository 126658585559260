import React, { Component } from 'react';
import axios from 'axios';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { withSnackbar } from 'notistack';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';

import GroupRow from './GroupRow';


class Groups extends Component {
  state = {
    formOpen: false,
    formBusy: false,
    newGroup: {name: '', footer: ''},
    groups: []
  };

  componentDidMount() {
    axios.get(`groups`)
      .then(res => {
        const groups = res.data.groups;
        this.setState({groups});
      })
  }

  handleFormOpen = () => {
    this.setState({ formOpen: true, newGroup: {name: '', footer: ''}, });
  };

  handleFormClose = () => {
    this.setState({ formOpen: false });
  };

  handleInputChange = (e, a) => {
    let {name, value} = e.target;
    let newGroup = this.state.newGroup;

    newGroup[name] = value;
    this.setState({newGroup});
  };

  groupSave = () => {
    if (this.state.newGroup.id) {
      this.groupUpdate();
    } else {
      this.groupCreate();
    }
  };

  groupUpdate = () => {
    this.setState({formBusy: true});
    let group = {...this.state.newGroup};

    axios.put('groups/' + group.id, group)
      .then( response =>{
        let groups = this.state.groups;
        let newGroup = response.data.group;
        let pos = groups.map(e => e.id).indexOf(newGroup.id);

        groups[pos] = newGroup;
        this.setState({groups, formBusy: false, formOpen: false});
      })
      .catch(err => {
        let error = err.response && err.response.data && err.response.data.error?  err.response.data.error : '';

        this.props.enqueueSnackbar('Błąd! ' + error, {variant: 'error'});
        this.setState({ formBusy: false });
      });
  }

  groupCreate = () => {
    let group = {...this.state.newGroup};

    this.setState({formBusy: true});
    axios.post(`groups`, group)
      .then(res => {
        let groups = this.state.groups;

        groups.push(res.data.group);
        this.setState({groups, formBusy: false, formOpen: false});
      })
      .catch( (err) => {
        this.setState({formBusy: false});
        let error = err.response && err.response.data && err.response.data.error?  err.response.data.error : '';

        this.props.enqueueSnackbar('Błąd! ' + error, {variant: 'error'});
      });
  };

  openEditHandler = data => {
    let newGroup = {...data};

    this.setState({newGroup, formOpen: true});
  };

  render() {
    let {groups, formBusy, newGroup} = this.state;
    const { classes } = this.props;

    return (
      <div className="padding15vw">
        <Fab color="secondary" aria-label="dodaj"
          className={classes.addButton}
          onClick={this.handleFormOpen}>
          <AddIcon />
        </Fab>
        <Dialog
          open={this.state.formOpen}
          onClose={this.handleFormClose}
          aria-labelledby="form-dialog-add"
          classes={{ paperScrollPaper: classes.overflow }}
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.groupSave}
            className="dialogModal"
          >
            <DialogContent className={classes.overflow}>
              <TextValidator
                autoFocus
                margin="dense"
                label="Nazwa"
                name="name"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newGroup.name}
                validators={['required']}
                errorMessages={['To pole jest wymagane']}
              />

              <TextValidator
                autoFocus
                margin="dense"
                label="Stopka emaila"
                name="footer"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newGroup.footer ? newGroup.footer : ''}
              />

            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleFormClose} color="primary">
                Anuluj
              </Button>
              <div className={classes.relative}>
                <Button type="submit" variant="contained" color="secondary" disabled={formBusy}>Zapisz</Button>
                {formBusy && <CircularProgress size={24} color="secondary" className={classes.buttonProgress} />}
              </div>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
        <Paper className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nazwa</TableCell>
                <TableCell>Stopka emaila</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups.map(data => {
                return (
                  <GroupRow key={data.id} data={data} onEdit={() => this.openEditHandler(data)}/>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  addButton: {
    position: 'fixed',
    top: '68px',
    right: '20px',
    zIndex: 99,
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      bottom: '20px'
    },
  },
  roleInput: {
    marginTop: '20px',
    marginBottom:  '20px'
  },
  relative: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialog: {
    minWidth: '60vw'
  },
  overflow: {
    overflow: 'visible'
  }
});

export default withSnackbar(withStyles(styles)(Groups));

import React, { Component } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";

import { bugsnagClient } from "../../bugsnag";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import { withSnackbar } from "notistack";

import AddressInput from "../AddressInput";
import DateInput from "../DateInput";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class OrderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      busy: false,
      anchorEl: null,
      newOrder: {
        when: moment(new Date())
          .add(30, "m")
          .toDate(),
        extraWhen: [],
        from: { address: "", city: "" },
        through: [],
        to: { address: "", city: "" },
        client: {
          count: 1,
          name: "",
          tel: ""
        },
        paymentMaker: "client",
        costCenter: "",
        notes: "",
        internalNotes: "",
        flightNumber: "",
        CompanyId: "",
        OrdererId: "",
        DriverId: "",
        driverNumber: "",
        timeNeeded: null,
        paymentAmount: null,
        confirmed: false
      },
      users: [],
      pricings: [],
      user: JSON.parse(sessionStorage.getItem("user"))
    };
  }

  componentDidMount() {
    this.props.companiesFetch();
    this.props.driversFetch();
  }

  componentWillReceiveProps({ selectedOrder, selectedDriver }) {
    if (selectedOrder && selectedOrder.id !== this.state.newOrder.id) {
      let order = {
        id: selectedOrder.id,
        when: selectedOrder.when,
        extraWhen: selectedOrder.extraWhen ? selectedOrder.extraWhen : [],
        from: selectedOrder.from,
        through: selectedOrder.through,
        to: selectedOrder.to,
        client: selectedOrder.client,
        paymentMaker: selectedOrder.paymentMaker,
        costCenter: selectedOrder.costCenter ? selectedOrder.costCenter : "",
        notes: selectedOrder.notes ? selectedOrder.notes : "",
        internalNotes: selectedOrder.internalNotes
          ? selectedOrder.internalNotes
          : "",
        flightNumber: selectedOrder.flightNumber
          ? selectedOrder.flightNumber
          : "",
        CompanyId: selectedOrder.CompanyId,
        OrdererId: selectedOrder.OrdererId,
        DriverId: selectedOrder.DriverId,
        driverNumber: selectedOrder.driverNumber
          ? selectedOrder.driverNumber
          : "",
        timeNeeded: selectedOrder.timeNeeded ? selectedOrder.timeNeeded : null,
        paymentAmount: selectedOrder.paymentAmount
          ? selectedOrder.paymentAmount
          : null,
        confirmed: selectedOrder.confirmed ? true : false
      };

      this.setState({ newOrder: order }, () => {
        this.loadPricings();
        if (order.CompanyId) this.loadUsers();
      });
    } else if (selectedDriver) {
      let newOrder = this.state.newOrder;
      newOrder.DriverId = selectedDriver;

      this.setState({ newOrder });
    } else if (!this.state.newOrder.when) {
      this.setDefaultOrder();
    }
  }

  setDefaultOrder = () => {
    this.setState({
      newOrder: {
        when: new Date(),
        extraWhen: [],
        from: { address: "", city: "", lat: "", lng: "" },
        through: [],
        to: { address: "", city: "", lat: "", lng: "" },
        client: {
          count: 1,
          name: "",
          tel: ""
        },
        paymentMaker: "client",
        costCenter: "",
        notes: "",
        internalNotes: "",
        flightNumber: "",
        CompanyId: "",
        OrdererId: "",
        DriverId: "",
        driverNumber: "",
        timeNeeded: "",
        paymentAmount: "",
        confirmed: false
      }
    });
  };

  loadUsers = (setDefaultUser = false) => {
    let companyId = this.state.newOrder.CompanyId;

    axios
      .get(`users/getcompanyusers/` + companyId)
      .then(res => {
        let users = res.data.users;
        let newOrder = { ...this.state.newOrder };

        if ((setDefaultUser || !newOrder.OrdererId) && users[0]) {
          newOrder.OrdererId = users[0].id;
        }

        this.setState({ users, newOrder });
      })
      .catch(err => {
        this.props.enqueueSnackbar(
          "Błąd! Nie udało się załadować listy zamawiających",
          { variant: "error" }
        );
      });
  };

  loadPricings = () => {
    let companyId = this.state.newOrder.CompanyId;

    axios
      .get(`pricings/` + companyId)
      .then(res => {
        const pricings = res.data.pricings;
        this.setState({ pricings });
      })
      .catch(err => {
        this.props.enqueueSnackbar("Błąd! Nie udało się załadować cennika", {
          variant: "error"
        });
      });
  };

  handleInputChange = (e, a) => {
    let { name, value } = e.target;
    let newOrder = this.state.newOrder;

    // reset costCenter if paymentMaker changed to client
    if (name === "paymentMaker" && value === "client") {
      newOrder.costCenter = "";
    }

    newOrder[name] = value;
    this.setState({ newOrder });
  };

  handleCompanyChange = (e, a) => {
    let { name, value } = e.target;
    let newOrder = this.state.newOrder;

    newOrder[name] = value;
    this.setState({ newOrder }, () => {
      this.loadUsers(true);
      this.loadPricings();
    });
  };

  handleClientChange = (e, a) => {
    let { name, value } = e.target;
    let newOrder = this.state.newOrder;

    if (name === "name") {
      value = value
        .toLowerCase()
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    }

    newOrder.client[name] = value;
    this.setState({ newOrder });
  };

  handleFromChange = address => {
    let newOrder = this.state.newOrder;

    newOrder.from = address;
    this.setState({ newOrder });
  };

  handleToChange = address => {
    let newOrder = this.state.newOrder;

    newOrder.to = address;
    this.setState({ newOrder });
  };

  addThroughPoint = () => {
    let newOrder = this.state.newOrder;

    newOrder.through = [
      ...newOrder.through,
      { address: "", city: "", lat: "", lng: "" }
    ];
    this.setState({ newOrder });
  };

  handleThroughChange = (address, index) => {
    let newOrder = this.state.newOrder;

    newOrder.through[index] = address;
    this.setState({ newOrder });
  };

  removeThroughPoint = index => {
    let newOrder = this.state.newOrder;

    newOrder.through.splice(index, 1);
    this.setState({ newOrder });
  };

  addExtraDate = () => {
    let newOrder = this.state.newOrder;
    let newDate = newOrder.when;

    if (newOrder.extraWhen.length) {
      newDate = newOrder.extraWhen[newOrder.extraWhen.length - 1];
    }

    newOrder.extraWhen.push(newDate);
    this.setState({ newOrder });
  };

  removeExtraDate = index => {
    let newOrder = this.state.newOrder;

    newOrder.extraWhen.splice(index, 1);
    this.setState({ newOrder });
  };

  handleDateChange = (date, fieldName, index = false) => {
    let newOrder = this.state.newOrder;
    if (index === false) {
      newOrder[fieldName] = date;
    } else {
      newOrder[fieldName][index] = date;
    }

    this.setState({ newOrder });
  };

  close = () => {
    this.props.ordersFormClose();
  };

  save = () => {
    let order = { ...this.state.newOrder };
    let removedThrough = _.remove(order.through, el => !el.city);

    if (removedThrough.length) {
      this.setState({ newOrder: order });
    }

    if (this.state.newOrder.id) {
      this.update();
    } else {
      this.create();
    }
  };

  create = () => {
    let order = { ...this.state.newOrder };

    this.setState({ busy: true });
    axios
      .post(`orders`, order)
      .then(res => {
        this.onChange();
        this.close();
      })
      .catch(err => {
        this.setState({ busy: false });
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Nie udało się zapisać";

        bugsnagClient.notify(err, {
          metaData: {
            type: "create/POST",
            order: order,
            err: err
          }
        });

        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  update = () => {
    this.setState({ busy: true });
    let order = { ...this.state.newOrder };

    axios
      .put("orders/" + order.id, order)
      .then(response => {
        this.onChange();
        this.close();
      })
      .catch(err => {
        this.setState({ busy: false });
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Nie udało się zapisać";

        bugsnagClient.notify(err, {
          metaData: {
            type: "update/PUT",
            order: order,
            err: err
          }
        });

        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  onChange = (action, order) => {
    let event = new CustomEvent("orderChanged", {
      detail: { action: action, order: order }
    });
    document.dispatchEvent(event);
  };

  openPricing = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePricingClose = () => {
    this.setState({ anchorEl: null });
  };

  handlePricingSelect = price => {
    let newOrder = this.state.newOrder;

    newOrder.paymentAmount = price;
    this.setState({ newOrder });
  };

  confirm = () => {
    let newOrder = { ...this.state.newOrder };

    newOrder.confirmed = !newOrder.confirmed;

    this.setState({ newOrder });
  };

  validate = newOrder => {
    if (
      !newOrder.when ||
      !newOrder.from.city ||
      !newOrder.CompanyId ||
      !newOrder.OrdererId ||
      !newOrder.client.name
    )
      return false;
    return true;
  };

  normalizeDiactrics = string =>
    string
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\u0142/g, "l");

  render() {
    const { classes } = this.props;
    const { busy, newOrder, anchorEl } = this.state;

    const variants = {
      confirmed: {
        icon: "check_circle",
        color: "#a2cf6e"
      },
      other: {
        icon: "cancel",
        color: "#ab003c"
      }
    };

    const variant = newOrder.confirmed ? variants.confirmed : variants.other;

    const validated = this.validate(newOrder);

    let driverName = null;
    let companySelect = null;
    let ordererSelect = null;

    let pricingSelect = null;

    if (newOrder.DriverId) {
      let driver = this.props.drivers.find(d => d.id === newOrder.DriverId);
      driverName = driver.name;
    }

    if (this.state.pricings.length) {
      pricingSelect = (
        <React.Fragment>
          <Button
            aria-haspopup="true"
            onClick={this.openPricing}
            color="primary"
            variant="contained"
            style={{ marginTop: "5px" }}
          >
            Cennik
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handlePricingClose}
          >
            {this.state.pricings.map(c => (
              <MenuItem
                key={"pricinglist" + c.id}
                onClick={() => {
                  this.handlePricingSelect(c.paymentAmount);
                  this.handlePricingClose();
                }}
              >
                <span style={{ fontSize: ".8rem" }}>{c.city1}</span>
                <Icon
                  style={{
                    verticalAlign: "middle",
                    margin: "0 1em",
                    fontSize: ".8rem"
                  }}
                >
                  compare_arrows
                </Icon>
                <span style={{ fontSize: ".8rem" }}>{c.city2}</span>
                <span style={{ fontSize: ".8rem", marginLeft: "1em" }}>
                  {c.type}
                </span>
                <span style={{ fontSize: ".8rem", marginLeft: "1em" }}>
                  {c.paymentAmount} PLN
                </span>
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      );
    }

    companySelect = (
      <FormControl style={{ width: "100%" }}>
        <InputLabel htmlFor="firma">Firma</InputLabel>
        <Select
          native
          value={newOrder.CompanyId}
          onChange={this.handleCompanyChange}
          id="firma"
          name="CompanyId"
          autoWidth={true}
          className={newOrder.CompanyId ? "" : "inputEmpty"}
        >
          <option value="" />
          {this.props.companies.map(c => (
            <option key={"companys" + c.id} value={c.id}>
              {c.name}
            </option>
          ))}
        </Select>
      </FormControl>
    );

    if (this.state.users.length) {
      ordererSelect = (
        <FormControl style={{ width: "100%", marginTop: "1em" }}>
          <InputLabel htmlFor="firma">Zamawiający</InputLabel>
          <Select
            native
            value={newOrder.OrdererId}
            onChange={this.handleInputChange}
            id="orderer"
            name="OrdererId"
            autoWidth={true}
            className={newOrder.OrdererId ? "" : "inputEmpty"}
          >
            {this.state.users.map(c => (
              <option key={"userid" + c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </Select>
        </FormControl>
      );
    }

    return (
      <Dialog
        open={true}
        aria-labelledby="form-dialog-add"
        fullScreen
        TransitionComponent={Transition}
      >
        <ValidatorForm ref="form" onSubmit={this.save}>
          <Grid container spacing={16}>
            <Grid item md={6}>
              <DialogContent className={classes.overflow}>
                <div className={classes.dateContainer} style={{ margin: 0 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Kiedy
                  </Typography>
                  <DateInput
                    onChange={date => this.handleDateChange(date, "when")}
                    selectedDate={newOrder.when}
                    inputClass={newOrder.when ? "" : "inputEmpty"}
                  />
                  {newOrder.extraWhen.map((date, index) => (
                    <div className={classes.flexRow} key={index}>
                      <DateInput
                        onChange={date =>
                          this.handleDateChange(date, "extraWhen", index)
                        }
                        selectedDate={date}
                      />
                      <IconButton
                        color="secondary"
                        size="small"
                        onClick={() => this.removeExtraDate(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
                  {newOrder.id ? null : (
                    <Tooltip title="Zlecenie cykliczne" placement="right">
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        className={classes.throughButton}
                        onClick={this.addExtraDate}
                      >
                        <AddIcon />
                      </Button>
                    </Tooltip>
                  )}
                </div>
                <div className={classes.tripContainer}>
                  <Typography variant="subtitle1" gutterBottom>
                    Trasa
                  </Typography>
                  <AddressInput
                    onSelect={this.handleFromChange}
                    label="Skąd"
                    selectedAddress={newOrder.from.address}
                    selectedAddressObj={newOrder.from}
                    inputClass={newOrder.from.address ? "" : "inputEmpty"}
                  />
                  {newOrder.through.map((address, index) => (
                    <div className={classes.flexRow} key={index}>
                      <AddressInput
                        onSelect={address =>
                          this.handleThroughChange(address, index)
                        }
                        label="Przez"
                        selectedAddress={address.address}
                        selectedAddressObj={address}
                      />
                      <IconButton
                        color="secondary"
                        size="small"
                        onClick={() => this.removeThroughPoint(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
                  <Tooltip title="Dodaj adres pośredni" placement="right">
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      className={classes.throughButton}
                      onClick={this.addThroughPoint}
                    >
                      <AddIcon />
                    </Button>
                  </Tooltip>

                  <AddressInput
                    onSelect={this.handleToChange}
                    label="Dokąd"
                    selectedAddress={newOrder.to.address}
                    selectedAddressObj={newOrder.to}
                  />
                </div>
                <Grid
                  container
                  spacing={32}
                  className={classes.othersContainer}
                >
                  <Grid item md={6}>
                    <TextValidator
                      margin="dense"
                      label="Ilość osób"
                      name="count"
                      type="number"
                      inputProps={{ min: "1", step: "1" }}
                      onChange={this.handleClientChange}
                      fullWidth
                      value={newOrder.client.count}
                      validators={["required", "minNumber:1"]}
                      errorMessages={[
                        "To pole jest wymagane",
                        "Podaj liczbę pasażerów"
                      ]}
                    />
                    <TextValidator
                      margin="dense"
                      label="Imię i Nazwisko pasażera(ów)"
                      name="name"
                      type="text"
                      onChange={this.handleClientChange}
                      fullWidth
                      value={newOrder.client.name}
                      className={newOrder.client.name ? "" : "inputEmpty"}
                    />
                    <TextValidator
                      margin="dense"
                      label="Tel. Kontaktowy"
                      name="tel"
                      type="text"
                      onChange={this.handleClientChange}
                      fullWidth
                      value={newOrder.client.tel}
                    />
                    <Grid container spacing={8}>
                      <Grid item sm={6}>
                        <TextValidator
                          margin="dense"
                          label="Numer Lotu"
                          name="flightNumber"
                          type="text"
                          onChange={this.handleInputChange}
                          fullWidth
                          value={newOrder.flightNumber}
                        />
                      </Grid>
                      <Grid item sm={6} style={{ display: "flex" }}>
                        {newOrder.flightNumber &&
                        newOrder.flightNumber.length > 3 ? (
                          <Button
                            variant="outlined"
                            color="secondary"
                            style={{ alignSelf: "flex-end" }}
                            target="_blank"
                            size="small"
                            href={
                              "https://www.google.pl/search?q=" +
                              newOrder.flightNumber
                            }
                          >
                            Sprawdź
                            <Icon className={classes.rightIcon}>flight</Icon>
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={6}>
                    <FormControl component="fieldset" margin="dense">
                      <FormLabel component="legend">Kto płaci</FormLabel>
                      <RadioGroup
                        aria-label="payment maker"
                        name="paymentMaker"
                        value={newOrder.paymentMaker}
                        onChange={this.handleInputChange}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          value="company"
                          control={<Radio />}
                          label="Firma"
                        />
                        <FormControlLabel
                          value="client"
                          control={<Radio />}
                          label="Pasażer"
                        />
                      </RadioGroup>
                    </FormControl>
                    {newOrder.paymentMaker === "client" ? (
                      <Typography variant="overline" gutterBottom>
                        Płatność u kierowcy
                      </Typography>
                    ) : (
                      <TextValidator
                        margin="dense"
                        label="CC/MPK/Karta"
                        name="costCenter"
                        type="text"
                        onChange={this.handleInputChange}
                        fullWidth
                        value={newOrder.costCenter}
                        disabled={newOrder.paymentMaker === "client"}
                        className={newOrder.costCenter ? "" : "inputEmpty"}
                      />
                    )}
                  </Grid>
                </Grid>
                <TextField
                  label="Uwagi (Widoczne dla zamawiającego, kierowcy i dyspozytorów)"
                  multiline
                  rows="4"
                  name="notes"
                  onChange={this.handleInputChange}
                  value={newOrder.notes}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </DialogContent>
            </Grid>
            <Grid item md={6}>
              <DialogContent>
                <Grid container justify="center" spacing={16}>
                  {companySelect}
                  {ordererSelect}

                  <Grid item md={6} style={{ margin: "2em 0" }}>
                    <TextField
                      label="Kwota"
                      name="paymentAmount"
                      type="number"
                      step="0.01"
                      onChange={this.handleInputChange}
                      fullWidth
                      value={
                        newOrder.paymentAmount !== null
                          ? newOrder.paymentAmount
                          : ""
                      }
                      InputProps={{
                        type: "number",
                        step: "0.01"
                      }}
                    />
                  </Grid>
                  <Grid item md={6} style={{ margin: "2em 0", padding: "1em" }}>
                    {pricingSelect}
                  </Grid>

                  <Grid item md={6} style={{ margin: "1em 0" }}>
                    <TextField
                      label="Czas przejazdu"
                      name="timeNeeded"
                      type="number"
                      step="1"
                      onChange={this.handleInputChange}
                      fullWidth
                      value={
                        newOrder.timeNeeded !== null ? newOrder.timeNeeded : ""
                      }
                      InputProps={{
                        type: "number",
                        step: "1",
                        endAdornment: (
                          <InputAdornment position="end">min</InputAdornment>
                        )
                      }}
                      className={newOrder.timeNeeded ? "" : "inputEmpty"}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    style={{ margin: "1em 0", textAlign: "center" }}
                  >
                    {variant ? (
                      <div style={{ color: variant.color }}>
                        <Icon
                          className={classes.cursorPointer}
                          onClick={() => this.confirm(newOrder)}
                        >
                          {variant.icon}
                        </Icon>
                      </div>
                    ) : null}
                    {newOrder.confirmed ? "Potwierdzone" : "Niepotwierdzone"}
                  </Grid>
                  <Grid item md={6} style={{ display: "flex" }}>
                    {newOrder.when &&
                    newOrder.from.city &&
                    newOrder.timeNeeded ? (
                      <Button
                        color="primary"
                        onClick={() =>
                          this.props.driversModalOpen({
                            order: newOrder,
                            directPick: false
                          })
                        }
                      >
                        {driverName ? driverName : "Wybierz kierowcę"}
                      </Button>
                    ) : null}
                  </Grid>
                  <Grid item md={6}>
                    <TextValidator
                      margin="dense"
                      label="Nr. taxi"
                      name="driverNumber"
                      type="text"
                      onChange={this.handleInputChange}
                      fullWidth
                      value={newOrder.driverNumber}
                    />
                  </Grid>
                  <TextField
                    label="Notatki (widoczne dla kierowcy i dyspozytorów)"
                    multiline
                    rows="4"
                    name="internalNotes"
                    onChange={this.handleInputChange}
                    value={newOrder.internalNotes}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </DialogContent>
            </Grid>
          </Grid>
          <DialogActions style={{ marginBottom: "3rem", marginRight: "1em" }}>
            <Button onClick={this.close} color="primary">
              Anuluj
            </Button>
            <div className={classes.relative}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={busy || !validated}
              >
                {newOrder.id ? "Zapisz" : "Zamów"}
              </Button>
              {busy && (
                <CircularProgress
                  size={24}
                  color="secondary"
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

const styles = theme => ({
  relative: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  overflow: {
    overflow: "visible"
  },
  throughButton: {
    margin: theme.spacing.unit,
    marginLeft: 0,
    padding: 0
  },
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  tripContainer: {
    margin: "2em 0",
    padding: "2em 1em",
    paddingTop: 0,
    borderLeft: "4px solid #8bc34a"
  },
  dateContainer: {
    margin: "2em 0",
    padding: "2em 1em",
    paddingTop: 0,
    borderLeft: "4px solid #ab003c"
  },
  othersContainer: {
    margin: "2em 0",
    padding: "2em 1em",
    paddingTop: 0,
    borderLeft: "4px solid #2196f3"
  },
  addressCard: {
    cursor: "move",
    backgroundColor: "#a2cf6e"
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  cursorPointer: {
    cursor: "pointer"
  }
});

const mapStateToProps = state => {
  return {
    formOpen: state.orders.formOpen,
    selectedOrder: state.orders.selectedOrder,
    companies: state.companies.companies,
    selectedDriver: state.orders.selectedDriver,
    drivers: state.drivers.drivers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ordersFormClose: () => dispatch(actionCreators.ordersFormClose()),
    companiesFetch: () => dispatch(actionCreators.companiesFetch()),
    driversFetch: () => dispatch(actionCreators.driversFetch()),
    driversModalOpen: payload =>
      dispatch(actionCreators.driversModalOpen(payload))
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSnackbar(OrderForm))
);

import React from "react";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

let AmountsCount = props => {
  const { orders } = props;

  let companyTotal = 0;
  let clientTotal = 0;

  orders.forEach(item => {
    if (!item.paymentAmount) return null;

    if (item.paymentMaker === "company") {
      companyTotal += parseFloat(item.paymentAmount);
    }
    if (item.paymentMaker === "client") {
      clientTotal += parseFloat(item.paymentAmount);
    }
    return null;
  });

  return (
    <TableRow>
      <TableCell colSpan="8"></TableCell>

      <TableCell
        align="center"
        style={{ backgroundColor: "rgba(33,150,243, 0.1)", fontWeight: "bold" }}
      >
        {companyTotal.toFixed(2)}
      </TableCell>
      <TableCell
        align="center"
        style={{ backgroundColor: "rgba(33,150,243, 0.1)", fontWeight: "bold" }}
      >
        {clientTotal.toFixed(2)}
      </TableCell>

      <TableCell colSpan="5"></TableCell>
    </TableRow>
  );
};

export default AmountsCount;

import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';

let GroupRow = (props) => {
  const { data } = props;

  return (
    <TableRow
      hover
      key={data.id}
      >
      <TableCell>
        {data.name}
      </TableCell>
      <TableCell>
        {data.footer}
      </TableCell>
      <TableCell>
        <IconButton style={{float: 'right'}} aria-label="Edit" onClick={props.onEdit}>
          <CreateIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}


export default GroupRow;

import React, { Component } from "react";
import axios from "axios";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CircularProgress from "@material-ui/core/CircularProgress";

class Xfirma extends Component {
  state = {
    busyImp: false,
    busyExp: false,
    count: "",
    amount: ""
  };

  componentDidMount() {
    axios.get(`orders/export/count`).then(res => {
      this.setState({ count: res.data.count, amount: res.data.amount });
    });
  }

  export = () => {
    this.setState({ busyExp: true });
    axios
      .get("orders/export")
      .then(res => {
        let hiddenElement = document.createElement("a");
        hiddenElement.href =
          "data:text/csv;charset=utf-8," + encodeURI(res.data);
        hiddenElement.target = "_blank";
        hiddenElement.download = "fly_export.csv";
        hiddenElement.click();
        this.setState({ busyExp: false });
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Nie udało się pobrać zleceń";

        this.props.enqueueSnackbar(error, { variant: "error" });
        this.setState({ busyExp: false });
      });
  };

  loadCSV = ({ target }) => {
    const formData = new FormData();

    this.setState({ busyImp: true });
    formData.append("csv", target.files[0]);

    axios
      .post("orders/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {
        this.setState({ busyImp: false });

        let confirmationBody = (
          <React.Fragment>
            <DialogContent>
              Zaktualizowanych zleceń: <strong>{res.data.goodCount}</strong>
              {res.data.badCount ? (
                <p style={{ color: "red" }}>
                  Nie udało się zaktualizować{" "}
                  <strong>{res.data.badCount}</strong> zleceń.{" "}
                </p>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={this.props.simpleDialogClose}
              >
                Ok
              </Button>
            </DialogActions>
          </React.Fragment>
        );

        this.props.simpleDialogOpen(confirmationBody);
      })
      .catch(err => {
        this.setState({ busyImp: false });
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Sprawdź plik";

        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  render() {
    let { busyExp, busyImp } = this.state;
    const { classes } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item sm={6}>
          {/* <Paper elevation={1}>
            <div className={classes.flex}>
              <div className={classes.box}>
                ilość zleceń:
                <span className={classes.number} style={{color: '#f44336'}}>{this.state.count}</span>
              </div>
              <div className={classes.box}>
                suma:
                <span className={classes.number} style={{color: '#2196f3'}}>{this.state.amount}</span>
              </div>
            </div>
            <p className={classes.flex} style={{color: '#777', fontSize: '0.8em'}}>* zlecenia, których data rozpoczęcia jest nie większa niż dwie godziny temu</p>
            <div className={classes.flex}>
              <div className={classes.relative}>
                <Button type="submit" variant="contained" color="secondary" disabled={busyExp} onClick={this.export}>Eksportuj do xFirmy</Button>
                {busyExp && <CircularProgress size={24} color="secondary" className={classes.buttonProgress} />}
              </div>
            </div>
          </Paper> */}
        </Grid>
        <Grid item sm={6}>
          <Paper style={{ padding: "2em", textAlign: "center" }} elevation={1}>
            <h2>Aktualizacja zleceń wysłanych do OPST</h2>
            <div className={classes.relative}>
              <Button
                variant="contained"
                component="label"
                color="secondary"
                disabled={busyImp}
              >
                Wgraj csv z xFirmy
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={this.loadCSV}
                />
              </Button>
              {busyImp && (
                <CircularProgress
                  size={24}
                  color="secondary"
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  box: {
    margin: "2em",
    color: "#777",
    textAlign: "center"
  },
  number: {
    display: "block",
    fontSize: "4em"
  },
  relative: {
    position: "relative",
    padding: " 1.4em"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

const mapDispatchToProps = dispatch => {
  return {
    simpleDialogOpen: payload =>
      dispatch(actionCreators.simpleDialogOpen(payload)),
    simpleDialogClose: () => dispatch(actionCreators.simpleDialogClose())
  };
};

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps
  )(withSnackbar(Xfirma))
);

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    user: null
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_SET:
            return updateObject(state, {user: action.user});
        case actionTypes.AUTH_CLEAR:
            return updateObject(state, {user: null});
        default:
            return state;
    }
};

export default reducer;
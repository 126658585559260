import * as actionTypes from './actionTypes';

export const companiesSave = (payload) => {
    return {
        type: actionTypes.COMPANIES_SAVE,
        payload
    };
};

export const companiesUpdate = (payload) => {
    return {
        type: actionTypes.COMPANIES_UPDATE,
        payload
    };
};

export const companiesFetch = () => {
    return {
        type: actionTypes.COMPANIES_FETCH
    };
};

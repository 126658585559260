import React, { Component } from "react";
import axios from "axios";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Fab from "@material-ui/core/Fab";

import UserRow from "./UserRow";

class Users extends Component {
  state = {
    formOpen: false,
    formBusy: false,
    changePass: false,
    newUser: {
      role: "clientManager",
      login: "",
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      isActive: true,
      phone: "",
      CompanyId: "",
      ecologic_id: "",
      xfirma_id: "",
      driverNumber: "",
      canCreateUsers: 0,
      canCashless: 1,
      order: null,
      default_cc: "",
    },
    users: [],
    usersLoading: false,
    companies: [],
    groups: [],
    pagination: {
      page: 1,
      totalPages: 1,
      limit: 50,
      total: 0,
    },
    search: {
      term: "",
      role: "",
      CompanyId: "",
      isActive: "",
    },
  };

  componentDidMount() {
    this.loadUsers();
    this.props.companiesFetch();
    this.loadGroups();

    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.newUser.password) {
        return false;
      }
      return true;
    });
  }

  loadUsers = () => {
    if (this.state.usersLoading) return;

    let params = {
      page: this.state.pagination.page,
      limit: this.state.pagination.limit,
    };

    if (this.state.search.term !== "") params.term = this.state.search.term;
    if (this.state.search.role !== "") params.role = this.state.search.role;
    if (this.state.search.CompanyId !== "")
      params.CompanyId = this.state.search.CompanyId;
    if (this.state.search.isActive !== "")
      params.isActive = this.state.search.isActive;

    this.setState({ usersLoading: true });

    axios.get(`users`, { params: params }).then((res) => {
      const users = res.data.users;
      let pagination = { ...this.state.pagination };
      pagination.totalPages = Math.ceil(res.data.total / pagination.limit)
        ? Math.ceil(res.data.total / pagination.limit)
        : 1;
      pagination.total = res.data.total;

      this.setState({ users, pagination, usersLoading: false });
    });
  };

  loadGroups = () => {
    axios.get(`groups`).then((res) => {
      this.setState({ groups: res.data.groups });
    });
  };

  changePage = (num) => {
    let pagination = { ...this.state.pagination };
    pagination.page = pagination.page + num;

    this.setState({ pagination }, this.loadUsers);
  };

  handleFormOpen = () => {
    this.setState({
      formOpen: true,
      changePass: false,
      newUser: {
        role: "clientManager",
        login: "",
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        isActive: true,
        phone: "",
        CompanyId: "",
        ecologic_id: "",
        xfirma_id: "",
        driverNumber: "",
        canCreateUsers: 0,
        canCashless: 1,
        order: null,
        default_cc: "",
      },
    });
  };

  handleFormClose = () => {
    this.setState({ formOpen: false });
  };

  handleInputChange = (e, a) => {
    let { name, value } = e.target;
    let newUser = this.state.newUser;

    if (name === "login") {
      value = value.replace(/[^\w\s.]/gi, "").toLowerCase();
    }

    newUser[name] = value;
    this.setState({
      newUser,
    });
  };

  handleSearchChange = (e, a) => {
    let { name, value } = e.target;
    let search = this.state.search;

    search[name] = value;
    this.setState(
      {
        search,
        pagination: {
          page: 1,
          totalPages: 1,
          limit: 50,
        },
      },
      this.loadUsers
    );
  };

  openEditHandler = (data) => {
    let newUser = { ...data };

    newUser.password = "";
    newUser.password_confirmation = "";

    this.setState({ newUser: newUser, formOpen: true, changePass: false });
  };

  handleActiveSwitchChange = (event) => {
    let data = this.state.newUser;

    data.isActive = event.target.checked;
    this.setState({ newUser: data });
  };

  handleCanCreateUsersSwitchChange = (event) => {
    let data = this.state.newUser;

    data.canCreateUsers = event.target.checked;
    this.setState({ newUser: data });
  };

  handleCanCashlessSwitchChange = (event) => {
    let data = this.state.newUser;

    data.canCashless = event.target.checked;
    this.setState({ newUser: data });
  };

  userSave = () => {
    if (this.state.newUser.id) {
      this.userUpdate();
    } else {
      this.userCreate();
    }
  };

  userUpdate = () => {
    this.setState({ formBusy: true });
    let user = { ...this.state.newUser };

    user.isActive = user.isActive ? 1 : 0;
    user.canCreateUsers = user.canCreateUsers ? 1 : 0;
    user.canCashless = user.canCashless ? 1 : 0;

    if (!user.password) {
      delete user.password;
      delete user.password_confirmation;
    }

    axios
      .put("users/" + user.id, user)
      .then((response) => {
        this.loadUsers();
        this.setState({ formBusy: false, formOpen: false });
      })
      .catch((err) => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";
        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
        this.setState({ formBusy: false });
      });
  };

  userCreate = () => {
    let user = { ...this.state.newUser };

    delete user.password_confirmation;
    user.isActive = user.isActive ? 1 : 0;
    user.canCreateUsers = user.canCreateUsers ? 1 : 0;
    user.canCashless = user.canCashless ? 1 : 0;

    this.setState({ formBusy: true });
    axios
      .post(`users`, user)
      .then((res) => {
        this.loadUsers();
        this.setState({ formBusy: false, formOpen: false });
      })
      .catch((err) => {
        this.setState({ formBusy: false });
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  changePass = () => {
    this.setState({ changePass: true });
  };

  render() {
    let { users, formBusy, newUser, changePass } = this.state;
    const { classes } = this.props;
    const isEdited = newUser.id ? true : false;

    if (isEdited) {
      newUser.isActive = newUser.isActive ? true : false;
    }

    let search = (
      <div className={classes.flex}>
        <FormControl
          className={classes.formControl}
          style={{ marginLeft: "auto" }}
        >
          <TextField
            id="search"
            label="Szukaj"
            name="term"
            value={this.state.search.term}
            onChange={this.handleSearchChange}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="typ">Typ</InputLabel>
          <Select
            value={this.state.search.role}
            onChange={this.handleSearchChange}
            inputProps={{
              name: "role",
              id: "typ",
            }}
          >
            <MenuItem value="">---</MenuItem>
            <MenuItem value="guest">Gość</MenuItem>
            <MenuItem value="client">Klient</MenuItem>
            <MenuItem value="clientManager">Klient Manager</MenuItem>
            <MenuItem value="driver">Kierowca</MenuItem>
            <MenuItem value="dispatcher">Dyspozytor</MenuItem>
            <MenuItem value="dispatcherManager">Dyspozytor Manager</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="firma">Firma</InputLabel>
          <Select
            native
            value={this.state.search.CompanyId}
            onChange={this.handleSearchChange}
            inputProps={{
              name: "CompanyId",
              id: "firma",
            }}
            style={{ width: "150px" }}
          >
            <option value="" />
            {this.props.companies.map((company) => (
              <option key={"scompany" + company.id} value={company.id}>
                {company.name}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="status">Status</InputLabel>
          <Select
            value={this.state.search.isActive}
            onChange={this.handleSearchChange}
            inputProps={{
              name: "isActive",
              id: "status",
            }}
          >
            <MenuItem value="">---</MenuItem>
            <MenuItem value={1}>Aktywny</MenuItem>
            <MenuItem value={0}>Nieaktywny</MenuItem>
          </Select>
        </FormControl>
      </div>
    );

    return (
      <div className="padding15vw">
        <Fab
          color="secondary"
          aria-label="dodaj"
          className={classes.addButton}
          onClick={this.handleFormOpen}
        >
          <AddIcon />
        </Fab>
        <Dialog
          open={this.state.formOpen}
          onClose={this.handleFormClose}
          scroll="body"
          aria-labelledby="form-dialog-add"
          disableBackdropClick={true}
        >
          <ValidatorForm ref="form" onSubmit={this.userSave}>
            <DialogContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={newUser.isActive}
                    onChange={this.handleActiveSwitchChange}
                    value="isActive"
                    color="primary"
                  />
                }
                label={newUser.isActive ? "Aktywny" : "Nieaktywny"}
              />

              <TextValidator
                margin="dense"
                label="Nazwa"
                name="name"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newUser.name ? newUser.name : ""}
                validators={["required"]}
                errorMessages={["To pole jest wymagane"]}
              />

              <Select
                native
                className={classes.select}
                value={
                  this.state.newUser.CompanyId
                    ? this.state.newUser.CompanyId
                    : ""
                }
                onChange={this.handleInputChange}
                fullWidth
                label="Firma"
                inputProps={{
                  name: "CompanyId",
                  id: "CompanyId",
                }}
              >
                <option value="" />
                {this.props.companies.map((company) => (
                  <option key={"firma" + company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Select>
              <FormHelperText>Firma</FormHelperText>

              <TextValidator
                margin="dense"
                label="Email"
                name="email"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newUser.email ? newUser.email : ""}
                validators={["required"]}
                errorMessages={["To pole jest wymagane"]}
              />

              <FormHelperText>
                Możliwe jest podanie więcej niż jednego adresu. Adresy należy
                oddzielić przecinkiem.
              </FormHelperText>

              <TextValidator
                margin="dense"
                label="Telefon"
                name="phone"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newUser.phone}
              />

              <TextValidator
                autoFocus
                margin="dense"
                label="Login"
                name="login"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newUser.login}
                disabled={isEdited}
                validators={["required"]}
                errorMessages={["To pole jest wymagane"]}
              />

              {isEdited && !changePass ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.changePass}
                  style={{ marginTop: "2rem" }}
                >
                  Zmień hasło
                </Button>
              ) : (
                <React.Fragment>
                  <TextValidator
                    margin="dense"
                    label="Hasło"
                    name="password"
                    type="text"
                    onChange={this.handleInputChange}
                    fullWidth
                    value={newUser.password}
                    validators={isEdited ? [] : ["required"]}
                    errorMessages={["To pole jest wymagane"]}
                  />

                  <TextValidator
                    margin="dense"
                    label="Potwierdzenie hasła"
                    name="password_confirmation"
                    type="text"
                    onChange={this.handleInputChange}
                    fullWidth
                    value={newUser.password_confirmation}
                    validators={
                      isEdited
                        ? ["isPasswordMatch"]
                        : ["isPasswordMatch", "required"]
                    }
                    errorMessages={[
                      "Hasła muszą być takie same",
                      "To pole jest wymagane",
                    ]}
                  />
                </React.Fragment>
              )}

              <Select
                className={classes.roleInput}
                value={this.state.newUser.role}
                onChange={this.handleInputChange}
                fullWidth
                label="typ"
                inputProps={{
                  name: "role",
                  id: "role",
                }}
              >
                <MenuItem value="guest">Gość</MenuItem>
                <MenuItem value="client">Klient</MenuItem>
                <MenuItem value="clientManager">Klient Manager</MenuItem>
                <MenuItem value="driver">Kierowca</MenuItem>
                <MenuItem value="dispatcher">Dyspozytor</MenuItem>
                <MenuItem value="dispatcherManager">
                  Dyspozytor Manager
                </MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </Select>

              <FormControlLabel
                control={
                  <Switch
                    checked={newUser.canCashless ? true : false}
                    onChange={this.handleCanCashlessSwitchChange}
                    value="canCashless"
                    color="secondary"
                  />
                }
                label={
                  newUser.canCashless
                    ? "Może zamawiać bezgotówkowo"
                    : "NIE może zamawiać bezgotówkowo"
                }
              />
              {newUser.canCashless ? (
                <TextValidator
                  margin="dense"
                  label="Domyślne CC"
                  name="default_cc"
                  type="text"
                  onChange={this.handleInputChange}
                  fullWidth
                  value={newUser.default_cc}
                />
              ) : null}

              {this.state.newUser.role === "clientManager" ? (
                <FormControlLabel
                  style={{ marginTop: "20px" }}
                  control={
                    <Switch
                      checked={newUser.canCreateUsers ? true : false}
                      onChange={this.handleCanCreateUsersSwitchChange}
                      value="canCreateUsers"
                      color="secondary"
                    />
                  }
                  label={
                    newUser.canCreateUsers
                      ? "Może zarządzać użytkownikami"
                      : "NIE może zarządzać użytkownikami"
                  }
                />
              ) : null}

              {this.state.newUser.role === "driver" ? (
                <React.Fragment>
                  <TextValidator
                    margin="dense"
                    label="Ecologic id"
                    name="ecologic_id"
                    type="text"
                    onChange={this.handleInputChange}
                    fullWidth
                    value={newUser.ecologic_id ? newUser.ecologic_id : ""}
                  />

                  <TextValidator
                    margin="dense"
                    label="Identyfikator XFIRMA"
                    name="xfirma_id"
                    type="text"
                    onChange={this.handleInputChange}
                    fullWidth
                    value={newUser.xfirma_id ? newUser.xfirma_id : ""}
                  />

                  <TextValidator
                    margin="dense"
                    label="Numer wywoławczy"
                    name="driverNumber"
                    type="text"
                    onChange={this.handleInputChange}
                    fullWidth
                    value={newUser.driverNumber ? newUser.driverNumber : ""}
                  />

                  <TextValidator
                    margin="dense"
                    label="Kolejność w tabeli"
                    name="order"
                    type="number"
                    onChange={this.handleInputChange}
                    fullWidth
                    value={newUser.order ? newUser.order : ""}
                  />
                </React.Fragment>
              ) : null}

              <FormHelperText style={{ marginTop: "15px" }}>
                <strong>Gość</strong> - Widzi tylko swoje zlecenia z okresu 30
                dni.
                <br />
                <strong>Klient</strong> - może zamówić przejazd na koszt firmy.
                Widzi tylko swoje zlecenia
                <br />
                <strong>Klient Manager</strong> - może zamówić przejazd, widzi
                wszystkie zamówienia firmy oraz może zarządzać użytkownikami
                firmy jeśli zostanie uprawniony. <br />
                <strong>Kierowca</strong> - po prostu kierowca
                <br />
                <strong>Dyspozytor</strong> - zarządza zamówieniami
                <br />
                <strong>Dyspozytor Manager</strong> - zarządza zamówieniami i
                może ustawić kierowcy nieobecność
                <br />
                <strong>Admin</strong> - ♫ uu, może wszystko ♫<br />
              </FormHelperText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleFormClose} color="primary">
                Anuluj
              </Button>
              <div className={classes.relative}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={formBusy}
                >
                  Zapisz
                </Button>
                {formBusy && (
                  <CircularProgress
                    size={24}
                    color="secondary"
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
        {search}
        <Paper className={classes.root}>
          <Table padding="dense">
            <TableHead>
              <TableRow>
                <TableCell>Nazwa</TableCell>
                <TableCell>Login</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Telefon</TableCell>
                <TableCell>Typ</TableCell>
                <TableCell>Firma</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((data) => {
                return (
                  <UserRow
                    key={data.id}
                    data={data}
                    onEdit={() => this.openEditHandler(data)}
                  />
                );
              })}
            </TableBody>
          </Table>
        </Paper>
        <div className={classes.flex}>
          <div className={classes.paginationNumbers}>
            {this.state.pagination.total} użytkowników
          </div>
          <div className={classes.flexGrow} />
          {this.state.pagination.page === 1 ? null : (
            <Icon
              onClick={() => this.changePage(-1)}
              className={classes.pointer}
            >
              chevron_left
            </Icon>
          )}
          <span className={classes.paginationNumbers}>
            {this.state.pagination.page} / {this.state.pagination.totalPages}
          </span>
          {this.state.pagination.page ===
          this.state.pagination.totalPages ? null : (
            <Icon
              onClick={() => this.changePage(1)}
              className={classes.pointer}
            >
              chevron_right
            </Icon>
          )}
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  addButton: {
    position: "fixed",
    top: "68px",
    right: "20px",
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      top: "auto",
      bottom: "20px",
    },
  },
  roleInput: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  select: {
    marginTop: "20px",
  },
  relative: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  flex: {
    display: "flex",
  },
  flexGrow: {
    flexGrow: 1,
  },
  paginationNumbers: {
    fontSize: "0.8em",
    lineHeight: "1.8em",
  },
  pointer: {
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});

const mapStateToProps = (state) => {
  return {
    companies: [...state.companies.companies],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    companiesFetch: () => dispatch(actionCreators.companiesFetch()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withStyles(styles)(Users)));

import React from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Icon from '@material-ui/core/Icon';

const styles = theme => ({
  root: {
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

class CompanySelect extends React.Component {
  state = {
    anchorEl: null,
    selectedCompany: {},
  };

  componentDidMount() {
    this.props.companiesFetch();
  }

  handleClickListItem = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (event, company) => {
    this.setState({ selectedCompany: company, anchorEl: null });
    this.props.selected(company);
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;

    return (
      <div className={classes.root}>
        <List component="nav">
          <ListItem
            button
            aria-haspopup="true"
            onClick={this.handleClickListItem}
          >
            <ListItemText
              primary={this.state.selectedCompany.name || 'Wybierz firmę'}
            />
            <Icon>keyboard_arrow_down</Icon>
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: 400,
              width: 360,
            },
          }}
        >
          {this.props.companies.map((company) => (
            <MenuItem
              key={'cs' + company.id}
              selected={company.id === this.state.selectedCompany.id}
              onClick={event => this.handleMenuItemClick(event, company)}
            >
              {company.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        companies: [...state.companies.companies]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        companiesFetch: () => dispatch(actionCreators.companiesFetch())
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CompanySelect));

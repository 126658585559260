import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    drivers: [],
    driversFetched: false,
    modalOpen: false,
    order: null,
    directPick: false
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.DRIVERS_SAVE:
            return updateObject(state, {drivers: action.payload, driversFetched: true});
        case actionTypes.DRIVERS_MODAL_OPEN:
            let payload = action.payload ? action.payload : {};
            return updateObject(state, {modalOpen: true, order: payload.order, directPick: payload.directPick});
        case actionTypes.DRIVERS_MODAL_CLOSE:
            return updateObject(state, {modalOpen: false, order: null, directPick: false});
        default:
            return state;
    }
};

export default reducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions/index';

import Dialog from '@material-ui/core/Dialog';

class SimpleDialog extends Component {


    render() {
      const { open, content,  ...other } = this.props;

      return (
        <Dialog {...other} open={open} onClose={() => this.props.close()}>
           {content}
        </Dialog>
      );
    }
  }

  const mapStateToProps = state => {
    return {
        content: state.simpleDialog.content,
        open: state.simpleDialog.open,
    }
  };

  const mapDispatchToProps = dispatch => {
    return {
        close: () => dispatch(actionCreators.simpleDialogClose())
    }
  };

  export default connect(mapStateToProps, mapDispatchToProps)(SimpleDialog);

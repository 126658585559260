import React from "react";

import Chip from "@material-ui/core/Chip";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import Icon from "@material-ui/core/Icon";

let UserRow = (props) => {
  const { data } = props;
  let isActive = data.isActive ? (
    <Chip color="primary" label="Aktywny" style={{ margin: "8px 0" }} />
  ) : (
    <Chip color="secondary" label="Nieaktywny" style={{ margin: "8px 0" }} />
  );
  let role = "";

  switch (data.role) {
    case "client":
      role = "Klient";
      break;
    case "clientManager":
      role = "Klient Manager";
      break;
    case "driver":
      role = "Kierowca";
      break;
    case "dispatcher":
      role = "Dyspozytor";
      break;
    case "dispatcherManager":
      role = "Dyspozytor Manager";
      break;
    case "admin":
      role = "Admin";
      break;
    default:
      role = "Gość";
  }

  return (
    <TableRow hover key={data.id}>
      <TableCell component="th" scope="row">
        {data.name}
      </TableCell>
      <TableCell>{data.login}</TableCell>
      <TableCell>{data.email}</TableCell>
      <TableCell>{data.phone}</TableCell>
      <TableCell>
        {data.canCreateUsers ? (
          <div>
            <Icon>group</Icon>
          </div>
        ) : null}
        {role}
      </TableCell>
      <TableCell>{data.company.name}</TableCell>
      <TableCell>
        {isActive}
        <IconButton
          style={{ float: "right" }}
          aria-label="Edit"
          onClick={props.onEdit}
        >
          <CreateIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default UserRow;

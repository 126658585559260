import { put, cancel, select, call } from 'redux-saga/effects';
import * as actions from '../actions/companies';
import * as api from '../../api/companies';

const getCompaniesFetched = (state) => state.companies.companiesFetched
const getCompanies = (state) => state.companies.companies

export function* companiesFetchSaga(action) {
    let isFetched = yield select(getCompaniesFetched);

    if (isFetched) {
        yield cancel();
    } else {
        let companies = yield call(api.get);
        yield put(actions.companiesSave(companies));
    }

};

export function* companiesUpdateSaga(action) {
    let companies = yield select(getCompanies);
    let company = action.payload;
    let index = yield companies.findIndex(el => company.id === el.id);

    if (index > -1) {
        companies[index] = company;
    } else {
        companies.push(company)
    }

    yield companies.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    yield put(actions.companiesSave(companies));

};



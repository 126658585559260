import React from "react";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import moment from "moment";

class HolidaysRow extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      deleteConfirmationOpen: false,
    };
  }

  onDelete = () => {
    this.setState({ deleteConfirmationOpen: true });
  };

  confirmDelete = () => {
    this.props.onDelete();
    this.closeDialog();
  };

  closeDialog = () => {
    this.setState({ deleteConfirmationOpen: false });
  };

  render() {
    const { data, onEdit } = this.props;
    let dialog = null;

    if (this.state.deleteConfirmationOpen) {
      dialog = (
        <Dialog
          open={true}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
        >
          <DialogContent>Na pewno chcesz usunąć pauzę?</DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog}>Nie</Button>
            <Button onClick={this.confirmDelete} color="secondary">
              Tak
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return (
      <>
        {dialog}
        <TableRow hover key={data.id}>
          <TableCell component="th" scope="row">
            {data.user.name}
          </TableCell>
          <TableCell>
            {moment(data.start).format("DD.MM.YYYY, HH:mm")}
          </TableCell>
          <TableCell>{moment(data.end).format("DD.MM.YYYY, HH:mm")}</TableCell>
          <TableCell>
            <IconButton
              aria-label="Delete"
              style={{ float: "right" }}
              onClick={this.onDelete}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              style={{ float: "right" }}
              aria-label="Edit"
              onClick={onEdit}
            >
              <CreateIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </>
    );
  }
}

export default HolidaysRow;

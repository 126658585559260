import * as actionTypes from './actionTypes';

export const authSet = ( user ) => {
    return {
        type: actionTypes.AUTH_SET,
        user: user
    };
};

export const authClear = () => {
    return {
        type: actionTypes.AUTH_CLEAR
    };
};
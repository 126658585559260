import React from "react";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import Chip from "@material-ui/core/Chip";

let CompanyRow = (props) => {
  const { data } = props;
  let isActive = data.isActive ? (
    <Chip color="primary" label="Aktywna" style={{ margin: "8px 0" }} />
  ) : (
    <Chip color="secondary" label="Nieaktywna" style={{ margin: "8px 0" }} />
  );

  return (
    <TableRow hover key={data.id}>
      <TableCell component="th" scope="row">
        {data.name}
      </TableCell>
      <TableCell>{data.xfirma_id}</TableCell>
      <TableCell>
        {isActive}
        <IconButton
          style={{ float: "right" }}
          aria-label="Edit"
          onClick={props.onEdit}
        >
          <CreateIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default CompanyRow;

import React from "react";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Icon from "@material-ui/core/Icon";

let PricingRow = props => {
  const { data } = props;

  return (
    <TableRow hover key={data.id}>
      <TableCell component="th" scope="row">
        <span style={{ fontSize: "1rem" }}>{data.city1}</span>{" "}
        <Icon style={{ verticalAlign: "middle", margin: "0 1em" }}>
          compare_arrows
        </Icon>{" "}
        <span style={{ fontSize: "1rem" }}>{data.city2}</span>
      </TableCell>
      <TableCell>{data.type}</TableCell>
      <TableCell>{data.paymentAmount}</TableCell>
      <TableCell>
        <IconButton
          aria-label="Delete"
          style={{ float: "right" }}
          onClick={props.onDelete}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          style={{ float: "right" }}
          aria-label="Edit"
          onClick={props.onEdit}
        >
          <CreateIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default PricingRow;

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    open: false,
    driver: ''
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.MAP_CLOSE:
            return updateObject(state, {open: false});
        case actionTypes.MAP_OPEN:
            return updateObject(state, {open: true, driver: action.payload});
        default:
            return state;
    }
};

export default reducer;

import * as actionTypes from './actionTypes';

export const mapOpen = (payload) => {
    return {
        type: actionTypes.MAP_OPEN,
        payload
    };
};

export const mapClose = () => {
    return {
        type: actionTypes.MAP_CLOSE
    };
};

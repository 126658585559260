import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import * as actionCreators from "../../store/actions/index";

import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import CompanyRow from "./CompanyRow";

class Companies extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      formOpen: false,
      formBusy: false,
      newCompany: { name: "", xfirma_id: "", isActive: true },
      filter: "",
      groups: [],
    };
  }

  componentDidMount() {
    this.props.companiesFetch();
    this.loadGroups();
  }

  loadGroups = () => {
    axios.get(`groups`).then((res) => {
      this.setState({ groups: res.data.groups });
    });
  };

  handleFilterChange = (e, a) => {
    this.setState({ filter: e.target.value });
  };

  handleFormOpen = () => {
    this.setState({
      formOpen: true,
      newCompany: { name: "", xfirma_id: "", isActive: true },
    });
  };

  handleFormClose = () => {
    this.setState({ formOpen: false });
  };

  handleInputChange = (e, a) => {
    let { name, value } = e.target;
    let newCompany = this.state.newCompany;

    newCompany[name] = value;
    this.setState({
      newCompany: newCompany,
    });
  };

  handleActiveSwitchChange = (event) => {
    let data = this.state.newCompany;

    data.isActive = event.target.checked;
    this.setState({ newCompany: data });
  };

  openEditHandler = (data) => {
    let newCompany = { ...data };

    this.setState({ newCompany: newCompany, formOpen: true });
  };

  companySave = () => {
    if (this.state.newCompany.id) {
      this.companyUpdate();
    } else {
      this.companyCreate();
    }
  };

  companyUpdate = () => {
    this.setState({ formBusy: true });
    let company = { ...this.state.newCompany };

    company.isActive = company.isActive ? 1 : 0;

    axios
      .put("companies/" + company.id, company)
      .then((response) => {
        this.setState({ formBusy: false, formOpen: false });
      })
      .catch((err) => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
        this.setState({ formBusy: false });
      });
  };

  companyCreate = () => {
    let company = { ...this.state.newCompany };
    company.isActive = company.isActive ? 1 : 0;

    this.setState({ formBusy: true });
    axios
      .post(`companies`, company)
      .then((response) => {
        this.setState({ formBusy: false, formOpen: false });
      })
      .catch((err) => {
        this.setState({ formBusy: false });
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  render() {
    let { filter, formBusy, newCompany } = this.state;
    const { classes, companies } = this.props;
    const isEdited = newCompany.id ? true : false;
    let filteredCompanies = companies;

    if (isEdited) {
      newCompany.isActive = newCompany.isActive ? true : false;
    }

    if (filter) {
      let regex = "";
      let filters = filter.toLowerCase().split(" ");

      filters.forEach((filter) => {
        regex += "(?=.*" + filter + ")";
      });
      regex += ".*";

      filteredCompanies = _.filter(companies, (company) => {
        let arr = [company.name, company.xfirma_id];
        let srex = new RegExp(regex, "gm");

        return srex.exec(arr.join(" ").toLowerCase()) !== null;
      });
    }

    let heading = (
      <div className={classes.flex}>
        <Input
          placeholder="Filtruj"
          value={this.state.filter}
          onChange={this.handleFilterChange}
          className={classes.flexEnd}
        />
      </div>
    );

    let form = (
      <Dialog
        open={this.state.formOpen}
        onClose={this.handleFormClose}
        aria-labelledby="form-dialog-add"
        classes={{ paperScrollPaper: classes.overflow }}
      >
        <ValidatorForm
          ref="form"
          onSubmit={this.companySave}
          className="dialogModal"
        >
          <DialogContent className={classes.overflow}>
            <FormControlLabel
              control={
                <Switch
                  checked={newCompany.isActive}
                  onChange={this.handleActiveSwitchChange}
                  value="isActive"
                  color="primary"
                />
              }
              label={newCompany.isActive ? "Aktywna" : "Nieaktywna"}
            />
            <TextValidator
              autoFocus
              margin="dense"
              label="Nazwa"
              name="name"
              type="text"
              onChange={this.handleInputChange}
              fullWidth
              value={newCompany.name}
              validators={["required"]}
              errorMessages={["To pole jest wymagane"]}
            />

            <TextValidator
              margin="dense"
              label="XFIRMA ID"
              name="xfirma_id"
              type="text"
              onChange={this.handleInputChange}
              fullWidth
              value={newCompany.xfirma_id}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleFormClose} color="primary">
              Anuluj
            </Button>
            <div className={classes.relative}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={formBusy}
              >
                Zapisz
              </Button>
              {formBusy && (
                <CircularProgress
                  size={24}
                  color="secondary"
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );

    return (
      <div className="padding15vw">
        <Fab
          color="secondary"
          aria-label="dodaj"
          className={classes.addButton}
          onClick={this.handleFormOpen}
        >
          <AddIcon />
        </Fab>
        {heading}
        {form}
        <Paper className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nazwa</TableCell>
                <TableCell>XFIRMA id</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCompanies.map((data) => {
                return (
                  <CompanyRow
                    key={data.id}
                    data={data}
                    onEdit={() => this.openEditHandler(data)}
                  />
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  addButton: {
    position: "fixed",
    top: "68px",
    right: "20px",
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      top: "auto",
      bottom: "20px",
    },
  },
  roleInput: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  relative: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexEnd: {
    marginLeft: "auto",
  },
  select: {
    marginTop: "20px",
  },
});

const mapStateToProps = (state) => {
  return {
    companies: [...state.companies.companies],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    companiesFetch: () => dispatch(actionCreators.companiesFetch()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withStyles(styles)(Companies)));

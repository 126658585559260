import { put, cancel, select, call } from 'redux-saga/effects';
import * as actions from '../actions/drivers';
import * as api from '../../api/drivers';

const getDriversFetched = (state) => state.drivers.companiesFetched

export function* driversFetchSaga(action) {
    let isFetched = yield select(getDriversFetched);

    if (isFetched) {
        yield cancel();
    } else {
        let drivers = yield call(api.get);
        yield put(actions.driversSave(drivers));
    }
};

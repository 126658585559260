import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

import moment from "moment";

const initialState = {
  activeView: "daybyday",
  ordersTodayFetched: false,
  ordersTomorrowFetched: false,
  ordersToday: [],
  ordersTomorrow: [],
  ordersArchive: [],
  ordersArchiveDates: {
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD")
  },
  filters: {
    CompanyId: "",
    OrdererId: "",
    DriverId: "",
    driverNumber: "",
    costCenter: "",
    clientName: ""
  },
  historyOpen: false,
  selectedOrder: null,
  selectedDriver: null,
  simpleEditorOpen: false,
  selectedField: null,
  formOpen: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ORDERS_CHANGEVIEW:
      return updateObject(state, { activeView: action.payload });
    case actionTypes.ORDERS_TODAY_SAVE:
      return updateObject(state, { ordersToday: action.payload });
    case actionTypes.ORDERS_TOMORROW_SAVE:
      return updateObject(state, { ordersTomorrow: action.payload });
    case actionTypes.ORDERS_HISTORY_OPEN:
      return updateObject(state, {
        historyOpen: true,
        formOpen: false,
        selectedOrder: action.payload
      });
    case actionTypes.ORDERS_HISTORY_CLOSE:
      return updateObject(state, { historyOpen: false, selectedOrder: null });
    case actionTypes.ORDERS_FORM_OPEN:
      return updateObject(state, {
        formOpen: true,
        historyOpen: false,
        selectedDriver: null,
        selectedOrder: action.payload
      });
    case actionTypes.ORDERS_FORM_CLOSE:
      return updateObject(state, {
        formOpen: false,
        selectedDriver: null,
        selectedOrder: null
      });
    case actionTypes.ORDERS_DRIVER_SAVE:
      return updateObject(state, { selectedDriver: action.payload });
    case actionTypes.ORDERS_SIMPLE_EDITOR_OPEN:
      return updateObject(state, {
        simpleEditorOpen: true,
        historyOpen: false,
        selectedDriver: null,
        selectedOrder: action.payload.order,
        selectedField: action.payload.field
      });
    case actionTypes.ORDERS_SIMPLE_EDITOR_CLOSE:
      return updateObject(state, {
        simpleEditorOpen: false,
        selectedDriver: null,
        selectedOrder: null,
        selectedField: null
      });
    case actionTypes.ORDERS_ARCHIVE_SAVE:
      return updateObject(state, { ordersArchive: action.payload });
    case actionTypes.ORDERS_ARCHIVE_DATES_SAVE:
      return updateObject(state, { ordersArchiveDates: action.payload });
    case actionTypes.ORDERS_FILTERS_SAVE:
      return updateObject(state, { filters: action.payload });
    default:
      return state;
  }
};

export default reducer;

import React from "react";
import { connect } from "react-redux";
import * as actionCreators from "./store/actions/index";

import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Icon from "@material-ui/core/Icon";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { Route, Link, NavLink } from "react-router-dom";
import logo from "./images/logoWhite.svg";

import Users from "./components/users/Users";
import Groups from "./components/groups/Groups";
import Orders from "./components/orders/Orders";
import Companies from "./components/companies/Companies";
import Pricings from "./components/pricings/Pricings";
import Holidays from "./components/holidays/Holidays";
import Drivers from "./components/drivers/Drivers";
import PasswordChange from "./components/users/PasswordChange";
import Xfirma from "./components/xfirma/Xfirma";

import MapModal from "./components/MapModal";
import SimpleDialog from "./components/SimpleDialog";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  logo: {
    marginLeft: 12,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    minHeight: "48px !important",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    marginTop: "60px",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  flexGrow: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    textDecoration: "none",
    display: "block",
  },
  active: {
    backgroundColor: theme.palette.action.selected,
  },
  ordersViewButton: {
    padding: "3px 20px",
    borderRadius: 0,
  },
});

class Main extends React.Component {
  constructor(props) {
    super();
    document.title = `Fly`;
    this.state = {
      drawerOpen: false,
      anchorEl: null,
    };
  }

  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  handleSelfMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleSelfMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, location } = this.props;
    const { anchorEl } = this.state;
    const selfMenuOpen = Boolean(anchorEl);

    let ordersChangeViewButtons = (
      <div className={classes.flexGrow}>
        <Button
          onClick={() => this.props.ordersChangeView("daybyday")}
          size="small"
          variant="contained"
          className={classes.ordersViewButton}
        >
          Zlecenia
        </Button>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          className={classes.ordersViewButton}
        >
          Dzisiaj/Jutro
        </Button>
        <Button
          onClick={() => this.props.ordersChangeView("history")}
          size="small"
          variant="contained"
          className={classes.ordersViewButton}
        >
          Historia
        </Button>
      </div>
    );

    if (this.props.ordersActiveView === "daybyday") {
      ordersChangeViewButtons = (
        <div className={classes.flexGrow}>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            className={classes.ordersViewButton}
          >
            Zlecenia
          </Button>
          <Button
            onClick={() => this.props.ordersChangeView("manage")}
            size="small"
            variant="contained"
            className={classes.ordersViewButton}
          >
            Dzisiaj/Jutro
          </Button>
          <Button
            onClick={() => this.props.ordersChangeView("history")}
            size="small"
            variant="contained"
            className={classes.ordersViewButton}
          >
            Historia
          </Button>
        </div>
      );
    }

    if (this.props.ordersActiveView === "history") {
      ordersChangeViewButtons = (
        <div className={classes.flexGrow}>
          <Button
            onClick={() => this.props.ordersChangeView("daybyday")}
            size="small"
            variant="contained"
            className={classes.ordersViewButton}
          >
            Zlecenia
          </Button>
          <Button
            onClick={() => this.props.ordersChangeView("manage")}
            size="small"
            variant="contained"
            className={classes.ordersViewButton}
          >
            Dzisiaj/Jutro
          </Button>
          <Button
            color="secondary"
            size="small"
            variant="contained"
            className={classes.ordersViewButton}
          >
            Historia
          </Button>
        </div>
      );
    }

    console.log(this.props.user);
    return (
      <div className={classes.root}>
        <CssBaseline />
        <SimpleDialog />
        {this.props.isDriversModalOpen && <Drivers />}
        {this.props.mapModalOpen && <MapModal />}
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.drawerOpen,
          })}
        >
          <Toolbar disableGutters={!this.state.drawerOpen} variant="dense">
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: this.state.drawerOpen,
              })}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={logo}
              alt="fly"
              width="32"
              height="32"
              className={classes.logo}
            />
            <span style={{ marginLeft: "1em" }}>
              {this.props.user.group.name}
            </span>
            {location.pathname === "/" ? (
              ordersChangeViewButtons
            ) : (
              <div className={classes.flexGrow}></div>
            )}
            <IconButton
              aria-owns={selfMenuOpen ? "menu-appbar" : null}
              aria-haspopup="true"
              onClick={this.handleSelfMenuOpen}
              color="inherit"
            >
              <Icon>face</Icon>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={selfMenuOpen}
              onClose={this.handleSelfMenuClose}
            >
              <Link to="/password-change" className={classes.link}>
                <MenuItem onClick={this.handleClose}>Zmień hasło</MenuItem>
              </Link>
              <Link to="/logout" className={classes.link}>
                <MenuItem onClick={this.handleClose}>Wyloguj</MenuItem>
              </Link>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={this.state.drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem
              button
              component={NavLink}
              exact
              to="/"
              activeClassName={classes.active}
            >
              <ListItemIcon>
                <Icon>view_list</Icon>
              </ListItemIcon>
              <ListItemText primary="Zlecenia" />
            </ListItem>
            <ListItem button onClick={this.props.driversModalOpen}>
              <ListItemIcon>
                <Icon>local_taxi</Icon>
              </ListItemIcon>
              <ListItemText primary="Kierowcy" />
            </ListItem>
          </List>
          <Divider />
          {this.props.user.role === "admin" ? (
            <React.Fragment>
              <List>
                <ListItem
                  button
                  component={NavLink}
                  to="/xfirma"
                  activeClassName={classes.active}
                >
                  <ListItemIcon>
                    <Icon>cloud</Icon>
                  </ListItemIcon>
                  <ListItemText primary="xFirma" />
                </ListItem>
              </List>
              <Divider />
            </React.Fragment>
          ) : null}
          {this.props.user.role === "admin" ? (
            <List>
              <ListItem
                button
                component={NavLink}
                to="/groups"
                activeClassName={classes.active}
              >
                <ListItemIcon>
                  <Icon>public</Icon>
                </ListItemIcon>
                <ListItemText primary="Miasta" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/companies"
                activeClassName={classes.active}
              >
                <ListItemIcon>
                  <Icon>business</Icon>
                </ListItemIcon>
                <ListItemText primary="Firmy" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/pricings"
                activeClassName={classes.active}
              >
                <ListItemIcon>
                  <Icon>attach_money</Icon>
                </ListItemIcon>
                <ListItemText primary="Cenniki" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/users"
                activeClassName={classes.active}
              >
                <ListItemIcon>
                  <Icon>group</Icon>
                </ListItemIcon>
                <ListItemText primary="Użytkownicy" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/holidays"
                activeClassName={classes.active}
              >
                <ListItemIcon>
                  <Icon>person_add_disabled</Icon>
                </ListItemIcon>
                <ListItemText primary="Pauzy" />
              </ListItem>
            </List>
          ) : null}
          {this.props.user.role === "dispatcherManager" ? (
            <List>
              <ListItem
                button
                component={NavLink}
                to="/pricings"
                activeClassName={classes.active}
              >
                <ListItemIcon>
                  <Icon>attach_money</Icon>
                </ListItemIcon>
                <ListItemText primary="Cenniki" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/users"
                activeClassName={classes.active}
              >
                <ListItemIcon>
                  <Icon>group</Icon>
                </ListItemIcon>
                <ListItemText primary="Użytkownicy" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/holidays"
                activeClassName={classes.active}
              >
                <ListItemIcon>
                  <Icon>person_add_disabled</Icon>
                </ListItemIcon>
                <ListItemText primary="Pauzy" />
              </ListItem>
            </List>
          ) : null}
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: this.state.drawerOpen,
          })}
        >
          <Route exact path="/" component={Orders}></Route>
          <Route exact path="/groups" component={Groups}></Route>
          <Route exact path="/users" component={Users}></Route>
          <Route exact path="/companies" component={Companies}></Route>
          <Route exact path="/pricings" component={Pricings}></Route>
          <Route exact path="/holidays" component={Holidays}></Route>
          <Route
            exact
            path="/password-change"
            component={PasswordChange}
          ></Route>
          <Route exact path="/xfirma" component={Xfirma}></Route>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    ordersActiveView: state.orders.activeView,
    isDriversModalOpen: state.drivers.modalOpen,
    mapModalOpen: state.mapDialog.open,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    driversModalOpen: () => dispatch(actionCreators.driversModalOpen()),
    ordersChangeView: (payload) =>
      dispatch(actionCreators.ordersChangeView(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Main));

import * as actionTypes from './actionTypes';

export const driversFetch = () => {
    return {
        type: actionTypes.DRIVERS_FETCH
    };
};

export const driversSave = (payload) => {
    return {
        type: actionTypes.DRIVERS_SAVE,
        payload
    };
};

export const driversModalOpen = (payload) => {
    return {
        type: actionTypes.DRIVERS_MODAL_OPEN,
        payload
    };
};

export const driversModalClose = (payload) => {
    return {
        type: actionTypes.DRIVERS_MODAL_CLOSE,
        payload
    };
};


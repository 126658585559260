import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    companies: [],
    companiesFetched: false
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.COMPANIES_SAVE:
            return updateObject(state, {companies: action.payload, companiesFetched: true});
        default:
            return state;
    }
};

export default reducer;

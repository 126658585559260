import axios from 'axios';

export function create(payload) {
    return axios.post('companies', payload);
};

export function get() {
    return axios.get('companies')
        .then(response => response.data.companies);
};

export function update(companyId, payload) {
    return axios.put('companies/' + companyId, payload);
};

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import _ from "lodash";

import config from "../config";

import * as actionCreators from "../store/actions/index";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

class MapModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      locations: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {}
    };
  }

  componentDidMount() {
    this.props.driversFetch();
  }

  componentWillReceiveProps({ open }) {
    if (open) {
      this.loadLocations();
    }
  }

  loadLocations = () => {
    axios
      .get(`drivers/locations`)
      .then(res => {
        let locations = res.data.locations;
        let drivers = [];
        let driversWithLocations = [];

        if (this.props.driver === "all") {
          drivers = _.filter(this.props.drivers, d => d.ecologic_id);
        } else {
          drivers = [this.props.drivers.find(d => d.id === this.props.driver)];
        }

        drivers.forEach(driver => {
          let location = locations.find(l => l.deviceId === driver.ecologic_id);
          if (location) {
            driver.location = location;

            let ago = location.dateTime.year;
            ago += "-";
            ago +=
              String(location.dateTime.month).length < 2
                ? "0" + location.dateTime.month
                : location.dateTime.month;
            ago += "-";
            ago +=
              String(location.dateTime.day).length < 2
                ? "0" + location.dateTime.day
                : location.dateTime.day;
            ago += "T";
            ago +=
              String(location.dateTime.hour).length < 2
                ? "0" + location.dateTime.hour
                : location.dateTime.hour;
            ago += ":";
            ago +=
              String(location.dateTime.minute).length < 2
                ? "0" + location.dateTime.minute
                : location.dateTime.minute;
            ago += "Z";
            ago = moment(ago).fromNow();

            driver.location.ago = ago;
            driversWithLocations.push(driver);
          }
        });
        this.setState({ locations: driversWithLocations });
      })
      .catch(err => {
        console.log(err);
        this.props.enqueueSnackbar("Błąd! Spróbuj później", {
          variant: "error"
        });
      });
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  render() {
    let { locations } = this.state;
    const { classes } = this.props;
    let lat = 52.029532;
    let lng = 20.18803;
    let zoom = 9;

    if (this.props.driver !== "all" && locations.length) {
      lat = locations[0].location.coordinate.latitude;
      lng = locations[0].location.coordinate.longitude;
      zoom = 15;
    }

    return (
      <Dialog fullScreen open={this.props.open} onClose={this.props.mapClose}>
        <div className={classes.buttons}>
          <Tooltip title="Aktualizuj pozycje">
            <Button
              variant="contained"
              color="primary"
              onClick={this.loadLocations}
              aria-label="Odśwież"
              style={{ marginRight: "1em" }}
            >
              <Icon>refresh</Icon>
            </Button>
          </Tooltip>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.props.mapClose}
            aria-label="Zamknij"
          >
            <Icon>close</Icon>
          </Button>
        </div>
        {this.props.open ? (
          <Map
            google={this.props.google}
            center={{
              lat: lat,
              lng: lng
            }}
            zoom={zoom}
          >
            {locations.map(location => (
              <Marker
                key={"marker_" + location.id}
                title={location.name}
                positionDate={location.location.ago}
                speed={location.location.speed}
                onClick={this.onMarkerClick}
                position={{
                  lat: location.location.coordinate.latitude,
                  lng: location.location.coordinate.longitude
                }}
              />
            ))}

            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
            >
              <div>
                <strong>{this.state.selectedPlace.title}</strong>
                <p>Aktualizacja: {this.state.selectedPlace.positionDate}</p>
                <p>Prędkość: {this.state.selectedPlace.speed}</p>
              </div>
            </InfoWindow>
          </Map>
        ) : null}
      </Dialog>
    );
  }
}

const styles = {
  buttons: {
    position: "fixed",
    top: "1em",
    right: "1em",
    zIndex: 99
  }
};

const mapStateToProps = state => {
  return {
    drivers: [...state.drivers.drivers],
    open: state.mapDialog.open,
    driver: state.mapDialog.driver
  };
};

const mapDispatchToProps = dispatch => {
  return {
    driversFetch: () => dispatch(actionCreators.driversFetch()),
    mapClose: () => dispatch(actionCreators.mapClose())
  };
};

export default GoogleApiWrapper({
  apiKey: config.gmaps_api_key
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSnackbar(withStyles(styles)(MapModal)))
);

import * as actionTypes from './actionTypes';

export const simpleDialogOpen = (payload) => {
    return {
        type: actionTypes.SIMPLEDIALOG_OPEN,
        payload
    };
};

export const simpleDialogClose = () => {
    return {
        type: actionTypes.SIMPLEDIALOG_CLOSE
    };
};

import React, { Component } from "react";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import axios from "axios";
import { withSnackbar } from "notistack";

import Tooltip from "@material-ui/core/Tooltip";

const HtmlTooltip = withStyles((theme) => ({
  popper: {
    opacity: 1,
  },
  tooltip: {
    background: "#f5f5f9",
    color: "#000",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    opacity: 1,
    "& b": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}))(Tooltip);

class Trip extends Component {
  handleCLick = () => {
    if (this.props.selectable && this.props.driverId) {
      if (this.props.directPick) {
        this.assignToOrder();
      } else {
        this.props.orderDriverSave(this.props.driverId);
        this.props.driversModalClose();
      }
    }
  };

  handleDoubleClick = () => {
    if (!this.props.selectable && this.props.item && this.props.directPick) {
      this.props.driversModalOpen({ order: this.props.item, directPick: true });
    }
  };

  assignToOrder = () => {
    let order = { DriverId: this.props.driverId };

    axios
      .put("orders/" + this.props.item.id, order)
      .then((response) => {
        this.props.driversModalOpen({ order: null, directPick: true });
        this.props.onRefresh();

        let event = new CustomEvent("orderChanged", {
          detail: {
            action: "driverAdd",
            order: { id: this.props.item.id, DriverId: this.props.driverId },
          },
        });
        document.dispatchEvent(event);
      })
      .catch((err) => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Nie udało się zapisać";

        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  render() {
    const { item, currentDate, selectable, driverId } = this.props;

    let start = moment(item.when).utc(true);

    let end = moment(item.when).utc(true).add(item.timeNeeded, "minutes");
    let curDate = moment(currentDate).utc(true);
    let startOfDay = curDate.startOf("Day").utc(true);
    let hoursFromStartOfDay = moment.duration(start.diff(startOfDay)).asHours();
    let durationHours =
      curDate.format("DD") !== start.format("DD")
        ? moment.duration(end.diff(startOfDay)).asHours()
        : moment.duration(end.diff(start)).asHours();

    let bgColor = "#a5d6a7";
    let width =
      (durationHours / 24) * 100 < 100 ? (durationHours / 24) * 100 : 100;
    let left =
      curDate.format("DD") !== start.format("DD")
        ? 0
        : (hoursFromStartOfDay / 24) * 100;
    if (
      end.format("MMDD") < curDate.format("MMDD") &&
      parseInt(end.format("HHmm")) > 1800
    ) {
      return <div className="achtung" />;
    }

    if (
      start.format("YYYYMMDD") > curDate.format("YYYYMMDD") ||
      durationHours < 0
    ) {
      return null;
    }

    if (
      (item.from.city === "Łódź" && item.to.city === "Warszawa") ||
      (item.from.city === "Łódź" && item.to.city === "Warsaw")
    )
      bgColor = "#fff176";
    if (
      (item.from.city === "Warszawa" && item.to.city === "Łódź") ||
      (item.from.city === "Warsaw" && item.to.city === "Łódź")
    )
      bgColor = "#ff8a65";
    if (item.from.city === "Łódź" && item.to.city === "Łódź")
      bgColor = "#bcaaa4";

    let tooltipTextTrip = item.from.address;

    item.through.forEach((el) => {
      tooltipTextTrip += " >" + el.address;
    });
    tooltipTextTrip += " > " + item.to.address;

    let tooltipText = (
      <React.Fragment>
        <p>
          <strong>{moment(item.when).format("HH:mm")}</strong>
        </p>
        <strong>{tooltipTextTrip}</strong>
        <br />
        <br />
        <strong>Ilość osób:</strong>{" "}
        {item.client ? (
          item.client.count > 3 ? (
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "1.5em" }}
            >
              {item.client.count}
            </span>
          ) : (
            item.client.count
          )
        ) : null}
        <br />
        <br />
        <strong>Pasażer:</strong> {item.client ? item.client.name : null}
        <br />
        <strong>Zamawiający:</strong> {item.orderer ? item.orderer.name : null}{" "}
        ({item.company ? item.company.name : null})
        <br />
        <strong>Uwagi:</strong> {item.notes}
        <br />
        <strong>Uwagi wewn.:</strong> {item.internalNotes}
      </React.Fragment>
    );
    let styles = {
      width: width + "%",
      left: left + "%",
      backgroundColor: bgColor,
    };

    if (selectable && driverId) {
      styles.backgroundColor = "blue";
      styles.opacity = 0.3;
      styles.color = "white";
      styles.cursor = "pointer";
    }

    return (
      <HtmlTooltip title={tooltipText} placement="bottom">
        <span
          className="time-entry"
          style={styles}
          onClick={this.handleCLick}
          onDoubleClick={this.handleDoubleClick}
        >
          {curDate.format("DD") === start.format("DD") ? (
            <span className="cityLeft">
              {item.from && item.from.city
                ? item.from.city.substring(0, 1)
                : null}
            </span>
          ) : null}
          {curDate.format("DD") === end.format("DD") ? (
            <span className="cityRight">
              {item.to && item.to.city ? item.to.city.substring(0, 1) : null}
            </span>
          ) : null}
        </span>
      </HtmlTooltip>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    directPick: state.drivers.directPick,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    orderDriverSave: (payload) =>
      dispatch(actionCreators.ordersDriverSave(payload)),
    driversModalClose: () => dispatch(actionCreators.driversModalClose()),
    driversModalOpen: (payload) =>
      dispatch(actionCreators.driversModalOpen(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Trip));

import io from 'socket.io-client';
import * as actionTypes from './actions/actionTypes';
import config from '../config';



export const websocketInit = ( store ) => {
    let token = sessionStorage.getItem("token");
    token = token.replace('Bearer ', '');

    const socket = io( config.websocket,
        {
            'query': 'token=' + token
        });

    Object.keys(actionTypes).forEach( type => socket.on( type, ( payload ) => {
        store.dispatch({ type, payload });
    }

    )
  );
};

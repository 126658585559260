import * as actionTypes from "./actionTypes";

export const ordersChangeView = payload => {
  return {
    type: actionTypes.ORDERS_CHANGEVIEW,
    payload
  };
};

export const ordersTodaySave = payload => {
  return {
    type: actionTypes.ORDERS_TODAY_SAVE,
    payload
  };
};

export const ordersTomorrowSave = payload => {
  return {
    type: actionTypes.ORDERS_TOMORROW_SAVE,
    payload
  };
};

export const ordersUpdate = payload => {
  return {
    type: actionTypes.ORDERS_UPDATE,
    payload
  };
};

export const ordersUpdateDelete = payload => {
  return {
    type: actionTypes.ORDERS_UPDATE_DELETE,
    payload
  };
};

export const ordersTodayFetch = () => {
  return {
    type: actionTypes.ORDERS_TODAY_FETCH
  };
};

export const ordersTomorrowFetch = () => {
  return {
    type: actionTypes.ORDERS_TOMORROW_FETCH
  };
};

export const ordersHistoryOpen = payload => {
  return {
    type: actionTypes.ORDERS_HISTORY_OPEN,
    payload
  };
};

export const ordersHistoryClose = payload => {
  return {
    type: actionTypes.ORDERS_HISTORY_CLOSE
  };
};

export const ordersFormOpen = payload => {
  return {
    type: actionTypes.ORDERS_FORM_OPEN,
    payload
  };
};

export const ordersFormClose = () => {
  return {
    type: actionTypes.ORDERS_FORM_CLOSE
  };
};

export const ordersSimpleEditorClose = () => {
  return {
    type: actionTypes.ORDERS_SIMPLE_EDITOR_CLOSE
  };
};

export const ordersSimpleEditorOpen = payload => {
  return {
    type: actionTypes.ORDERS_SIMPLE_EDITOR_OPEN,
    payload
  };
};

export const ordersDriverSave = payload => {
  return {
    type: actionTypes.ORDERS_DRIVER_SAVE,
    payload
  };
};

export const ordersArchiveSave = payload => {
  return {
    type: actionTypes.ORDERS_ARCHIVE_SAVE,
    payload
  };
};

export const ordersArchiveDatesSave = payload => {
  return {
    type: actionTypes.ORDERS_ARCHIVE_DATES_SAVE,
    payload
  };
};

export const ordersFiltersSave = payload => {
  return {
    type: actionTypes.ORDERS_FILTERS_SAVE,
    payload
  };
};

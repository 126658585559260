import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import scriptLoader from "react-async-script-loader";
import config from "../config";
import _ from "lodash";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
class AddressInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      addressObj: {},
      ready: false,
      dragging: false,
      error: false,
      locationBias: "",
    };
  }

  componentWillReceiveProps({
    isScriptLoaded,
    isScriptLoadSucceed,
    selectedAddress,
    selectedAddressObj,
  }) {
    if (isScriptLoaded && !this.props.isScriptLoaded) {
      // load finished
      if (isScriptLoadSucceed) {
        this.setState({
          ready: true,
          locationBias: new window.google.maps.LatLng(51.7592, 19.456),
        });
      } else this.props.onError();
    }

    if (selectedAddress)
      this.setState({
        address: selectedAddress,
        addressObj: selectedAddressObj,
      });
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ ready: true });
    }
  }

  handleChange = (address) => {
    if (address.includes("}")) return false;
    this.setState({ address, addressObj: {} });
    this.props.onSelect({});
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        let city = _.find(results[0].address_components, (component) => {
          return component.types.find((type) => {
            return type === "locality";
          });
        });

        // Barry case when locality is "Street"
        if (city && city.long_name === "Street") {
          city = null;
        }

        if (!city) {
          city = _.find(results[0].address_components, (component) => {
            return component.types.find((type) => {
              return type === "administrative_area_level_2";
            });
          });
        }

        if (!city) {
          city = _.find(results[0].address_components, (component) => {
            return component.types.find((type) => {
              return type === "sublocality";
            });
          });
        }

        if (!city) {
          city = _.find(results[0].address_components, (component) => {
            return component.types.find((type) => {
              return type === "administrative_area_level_1";
            });
          });
        }

        if (!city) {
          city = { long_name: "" };
        }

        let addressObj = {
          address: address,
          city: city.long_name,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };

        this.setState({
          address: address,
          error: false,
          addressObj: addressObj,
        });
        this.props.onSelect(addressObj);
      })
      .catch((err) => {
        this.setState({ error: true });
      });
  };

  onDragOver = (e) => {
    this.dragging(true);
    e.stopPropagation();
    e.preventDefault();
  };

  onDrop = (e) => {
    let address = e.dataTransfer.getData("address");
    let city = e.dataTransfer.getData("city");

    if (address && city) {
      this.setState({ address, dragging: false });

      let addressObj = {
        address: address,
        city: city,
      };

      this.props.onSelect(addressObj);
    }
  };

  dragging = (dragging) => {
    if (this.state.draggin !== dragging) this.setState({ dragging });
  };

  render() {
    let input = "";
    let error = false;
    let { classes, label } = this.props;

    if (!label) label = "Adres";

    let inputStyle = {};

    if (this.state.error) {
      inputStyle = {
        backgroundColor: "red",
        color: "white",
      };
    }

    if (this.state.dragging) {
      inputStyle = {
        backgroundColor: "#a2cf6e",
      };
    }

    if (this.state.address && !this.state.addressObj.city) {
      label = "Wybierz adres z listy";
      error = true;
    }

    if (this.state.ready) {
      input = (
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          highlightFirstSuggestion={true}
          debounce={1000}
          shouldFetchSuggestions={this.state.address.length > 2}
          searchOptions={{ location: this.state.locationBias, radius: 400000 }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className={classes.container}>
              <TextField
                error={error ? true : false}
                label={label}
                value={this.state.address}
                margin="dense"
                fullWidth
                {...getInputProps({
                  placeholder: "Szukaj adresu ...",
                  className: "location-search-input",
                })}
                className={this.props.inputClass}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
                onDragLeave={() => this.dragging(false)}
                style={inputStyle}
              />
              <Paper square className={classes.paper}>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <MenuItem
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      {suggestion.description}
                    </MenuItem>
                  );
                })}
              </Paper>
            </div>
          )}
        </PlacesAutocomplete>
      );
    }
    return <React.Fragment>{input}</React.Fragment>;
  }
}

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: "relative",
    overflow: "visible",
  },
  paper: {
    position: "absolute",
    zIndex: 9999999999,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
});

export default scriptLoader(
  "https://maps.googleapis.com/maps/api/js?key=" +
    config.gmaps_api_key +
    "&libraries=places"
)(withStyles(styles)(AddressInput));
